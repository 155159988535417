import React, { Component } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  toast,
  ToastContainer,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBSpinner,
  MDBBtn,
  MDBIcon,
} from "mdbreact";
import Navigation from "../../../components/NetworkMgmt/Navigation/Navigation";
import QuickLinks from "../../../components/NetworkMgmt/QuickLinks/QuickLinksWithWizard";
import SummaryCardVenues from "../../../components/NetworkMgmt/Dashboard/SummaryCardVenues";
import SummaryCardSubscribers from "../../../components/NetworkMgmt/Dashboard/SummaryCardSubscribers";
import ViewVenuesTable from "../../../components/NetworkMgmt/Venues/Datatables/VenuesTableMUI";
import ModifyVenue from "../../../components/NetworkMgmt/Forms/ModifyVenue/ModifyVenue";
import axios from "../../../axios";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import LogoFooter from "../../../components/Logo/logoFooter";
const initialState = { isLoading: false, results: [], value: "" };
let getResults;
let source;
let source1 = [];
class Dashboard extends Component {
  state = {
    selectedName:
      sessionStorage.getItem("venueName") !== undefined
        ? sessionStorage.getItem("venueName")
        : null,
    initialState,
    venueData: [],
    activeData: undefined,
    refreshed: false,
    loading: false,
    venueType: "",
    buildings: [],
    darkmode: JSON.parse(localStorage.getItem("darkMode")),
    showError: false,
    errorMessage: undefined,
    venueAdminList: {},
    hasUpdated: false
  };
  componentDidMount() {
    this.getDisplayOptions();
    this.getDisplayOptionsList();
    this.getVenues();
    this.getVenueDetails();
    this.getUserInfo();
    this.getOperatorInfo();
    // document.title = "Wi-Fi Management Console";
  }
  searchData = () => {
    return this.state.searchData;
  };
  updateName = (value, index) => {
    sessionStorage.setItem("venueName", value);
    sessionStorage.setItem("venueID", index);
    this.setState({ selectedName: value });
    this.setState({ selectedID: index });
    this.setState({ venueID: index });
    this.getVenueDetails(index);
    this.getVenuesList();
  };
  updateVenueList = (venueListIn) => {
    let venueName1 = [];
    let venueListNameTemp = [];
    for (let count = 0; count <= Object.keys(venueListIn).length - 1; count++) {
      let venueList_a = Object.keys(venueListIn);
      venueName1[venueList_a[count]] =
        venueListIn[venueList_a[count]]["VenueName"];
      venueListNameTemp = [...venueListNameTemp, venueName1];
    }
    this.setState({
      venueList: venueListIn,
      venueListName: venueListNameTemp,
    });
  };
  getDisplayOptions = () => {
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
    };
    axios
      .post("/networkoperatorconsole/equipment/listOperatorDetails", body, {
        headers: {},
      })
      .then((response) => {
        const root = document.documentElement;
        var title = document.createElement("title");
        var description = document.createElement("meta");
        // console.log("theme changed")
        let theme;
        if (
          localStorage.getItem("darkMode") === "false" ||
          localStorage.getItem("darkMode") === null
        ) {
          theme = "ThemeLight";
        } else {
          theme = "ThemeDark";
        }
        // console.log(theme)
        root?.style.setProperty(
          "--background-color",
          response.data.DisplayConfiguration[theme]["BackgroundColorHex"]
        );
        root?.style.setProperty(
          "--modal-background-color",
          response.data.DisplayConfiguration[theme]["ModalColorHex"]
        );
        root?.style.setProperty(
          "--primary-text-color",
          response.data.DisplayConfiguration[theme]["PrimaryTextColorHex"]
        );
        root?.style.setProperty(
          "--base-color",
          response.data.DisplayConfiguration[theme]["PrimaryColorHex"]
        );
        root?.style.setProperty(
          "--secondary-color",
          response.data.DisplayConfiguration[theme]["SecondaryColorHex"]
        );
        root?.style.setProperty(
          "--secondary-text-color",
          response.data.DisplayConfiguration[theme]["SecondaryTextColorHex"]
        );
        root?.style.setProperty(
          "--border-color",
          response.data.DisplayConfiguration[theme]["BorderColorHex"]
        );
        root?.style.setProperty(
          "--base-color-rgb",
          response.data.DisplayConfiguration[theme]["PrimaryColorHex"] + "4D"
        );
        root?.style.setProperty(
          "--link-color",
          response.data.DisplayConfiguration[theme]["LinkColorHex"]
        );
        root?.style.setProperty(
          "--button-color",
          response.data.DisplayConfiguration[theme]["PrimaryColorHex"]
        );
        root?.style.setProperty(
          "--button-text-color",
          response.data.DisplayConfiguration[theme]["SecondaryTextColorHex"]
        );
        root?.style.setProperty(
          "--summary-card-color",
          response.data.DisplayConfiguration[theme]["SecondaryColorHex"]
        );
        root?.style.setProperty(
          "--navbar-text-color",
          response.data.DisplayConfiguration[theme]["PrimaryTextColorHex"]
        );
        root?.style.setProperty(
          "--navbar-color",
          response.data.DisplayConfiguration[theme]["NavbarColorHex"]
        );
        root?.style.setProperty(
          "--card-color",
          response.data.DisplayConfiguration[theme]["NavbarColorHex"]
        );
        root?.style.setProperty("--button-text-color", "#FFF");
        root?.style.setProperty(
          "--input-color",
          response.data.DisplayConfiguration[theme]["InputBoxColorHex"]
        );
        root?.style.setProperty(
          "--input-focus-color",
          response.data.DisplayConfiguration[theme]["BannerColor2Hex"]
        );
        sessionStorage.setItem(
          "cartEnabledGlobally",
          response.data.ShoppingCart.enableShoppingCart
        );
        this.setState({
          s3Directory:
            response.data.DisplayConfiguration["Behavior"]["S3Directory"],
          logo: response.data.DisplayConfiguration[theme][
            "LogoFileNameDesktopMobileAfterLogin"
          ],
          displayOptionID: response.data.DisplayOptionID,
          operatorName: response.data.NetworkOperatorName,
          cancelInputType:
            response.data["DisplayConfiguration"]["Behavior"][
              "CancellationMethod"
            ],
          showUpdate:
            response.data["DisplayConfiguration"]["ShoppingCart"][
              "AllowSelfPaymentMethodUpdate"
            ],
          showSupport:
            response.data.DisplayConfiguration["Support"]["ShowSupportLinks"],
          externalSupport:
            response.data.DisplayConfiguration["Support"][
              "ExternallyManagedSupport"
            ],
          externalSupportLink:
            response.data.DisplayConfiguration["Support"][
              "ExternalSupportSystemURL"
            ],
          showDevices:
            response.data.DisplayConfiguration[
              "ShowEquipmentStatusToVenueAdministrator"
            ],
          // globalNetworkConfiguration: response.data["NetworkConfiguration"],
          // globalMessagingConfiguration: response.data["Notifications"],
          // globalRealPageConfiguration: response.data["PMS"],
          // globalShoppingCartConfiguration: response.data["ShoppingCart"],
          // allowOverlapping:
          //   response.data["NetworkConfiguration"][
          //     "allowOverlappingSubscriptionWiredUnit"
          //   ],
          // selectDefaultServicePlanResident:
          //   response.data["ServicePlanConfiguration"][
          //     "DefaultServicePlanID_GlobalWirelessPlusLocalWiredAccess"
          //   ],
          // selectDefaultServicePlanWireless:
          //   response.data["ServicePlanConfiguration"][
          //     "DefaultServicePlanID_LocalWirelessAccess"
          //   ],
          // globalKeyPoolList:
          //   response.data["NetworkConfiguration"]["GlobalSSID"],
          operatorID: response.data["OperatorID"],
        });
        sessionStorage.setItem(
          "cancelInputType",
          response.data.DisplayConfiguration["Behavior"]["CancellationMethod"]
        );
        sessionStorage.setItem(
          "showUpdate",
          response.data.DisplayConfiguration["ShoppingCart"][
            "AllowSelfPaymentMethodUpdate"
          ]
        );
        sessionStorage.setItem("loggedInNO", true);
      })
      .catch((error) => {});
  };
  getDisplayOptionsList = () => {
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
    };
    this.setState({
      loading_info: true,
      operatorInfo: undefined,
    });
    axios
      .post("/operatorconsole/config/listDisplayOptions", body, {
        headers: {},
      })
      .then(
        (response) => {
          let optionTemplateDisplayOptions = [];
          this.setState({
            allDisplayOptions: response.data.DisplayOptions,
            allMetaOptions: response.data.metaOptions,
          });
          Object.keys(response.data.DisplayOptions).map((oneKey, i) => {
            optionTemplateDisplayOptions.push({
              value: response.data.DisplayOptions[oneKey]["DisplayOptionID"],
              name: response.data.DisplayOptions[oneKey]["DisplayOptionName"],
            });
            return true;
          });
          this.setState({ loading_info: false });
          this.setState({
            optionTemplateDisplayOptions: optionTemplateDisplayOptions,
            loading_info: false,
          });
        },
        (error) => {
          this.setState({ loading_info: false });
        }
      );
  };
  getVenues = async(selectedID) => {
    let body = { AccessToken: sessionStorage.getItem("AccessToken") };
    this.setState({
      attributeForm: [],
      venueListForTable: undefined,
    });
    await axios
      .post("/networkoperatorconsole/venues/listVenues", body, {
        headers: {},
      })
      .then((response) => {
        let firstID;
        this.setState({
          venueList: response.data,
          venueListForTable: response.data.VenueList,
        });
        var formArrayVenues = [];
        let optionTemplateVenues = [];
        // Object.keys(response.data.VenueList).map((oneKey, i) => {
        // 	if (i === 0 && selectedID === undefined) {
        // 		firstID = oneKey;
        // 		selectedID = oneKey;
        // 	}
        // 	if (selectedID === oneKey) {
        // 		optionTemplateVenues.push(
        // 			<MDBSelectOption selected value={oneKey} key={i}>
        // 				{response.data.VenueList[oneKey]["VenueName"]}
        // 			</MDBSelectOption>
        // 		);
        // 	} else {
        // 		optionTemplateVenues.push(
        // 			<MDBSelectOption value={oneKey} key={i}>
        // 				{response.data.VenueList[oneKey]["VenueName"]}
        // 			</MDBSelectOption>
        // 		);
        // 	}
        // 	return true;
        // });
        optionTemplateVenues.push({
         value: "",
         name: "Select..."
        })
        Object.keys(response.data.VenueList).map((oneKey, i) => {
          optionTemplateVenues.push({
            value: oneKey,
            name: response.data.VenueList[oneKey]["VenueName"],
          });
          return true;
        });
        this.setState({
          optionTemplateVenues: optionTemplateVenues,
          loading_prop_groups: false,
        });
        if (selectedID === undefined) {
          this.getVenueDetails(firstID);
        }
        formArrayVenues.push(
          <MDBSelect
            id="selectVenueResidentModal"
            getValue={this.handleSelectChangeVenue}
            className="form-control"
          >
            <MDBSelectInput
              className="selectInput venueSelectedResidentModal"
              selected="Choose your venue"
            />
            <MDBSelectOptions search>{optionTemplateVenues}</MDBSelectOptions>
          </MDBSelect>
        );
        this.setState({
          attributeForm: formArrayVenues,
        });
        this.getPropertyManagementGroups();
        if (this.state.venueAdminList.length !== 0) {
          this.getAdmins();
        }
      })
      .catch((error) => {
        this.setState({ showError: true });
        // this.setState({ errorMessage: "The listVenues API timed out"})
        this.setState({ error: true });
      });
  };
  getVenuesList = async(selectedID) => {
    let body = { AccessToken: sessionStorage.getItem("AccessToken") };
    this.setState({
      venueListForTable: undefined,
    });
    await axios
      .post("/networkoperatorconsole/venues/listVenues", body, {
        headers: {},
      })
      .then((response) => {
        this.setState({
          venueList: response.data,
          venueListForTable: response.data.VenueList,
        });
        this.getPropertyManagementGroups();
      })
      .catch((error) => {
        this.setState({ error: true });
      });
  };
  getVenueDetails = (index) => {
    this.setState({ 
     loadingVenueDetails: true 
    });
    let cookieVenueName = sessionStorage.getItem("venueID");
    cookieVenueName === null
      ? (cookieVenueName = "")
      : (index = cookieVenueName);
    this.setState({
      unitData: undefined,
      expiringData: undefined,
      searchData: undefined,
      value: "",
      servicePlans: undefined,
      keyPoolList: undefined,
      venueAdminList: {},
      venueEquipList: undefined,
      venueLeasingAgentList: {},
      staffArrayAll: undefined,
      venueInfo: undefined,
    });
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      VenueID: index,
      UserTimeZone: new Date().getTimezoneOffset(),
    };
    let key = [];
    let iteration = 0;
    let lastName = [];
    let firstName = [];
    let unitID = [];
    let unitID_internal = [];
    let keyID = [];
    let email = [];
    let startDate = [];
    let contactpref_email = [];
    let contactpref_sms = [];
    let phoneNumber = [];
    let locale = [];
    let endDate = [];
    let ssid = [];
    let servicePlanID = [];
    let servicePlan = [];
    let vlan = [];
    let residentStatus = [];
    let subscriptionDiscount = [];
    let venueDiscount = [];
    let externalSubscriberID = [];
    let primaryUnitID_Internal = [];
    let selectedServicePlanPrice = [];
    source1 = [];
    this.setState({
      activeData: undefined,
      activeData1Day: undefined,
      activeData7Day: undefined,
      expiringData: undefined,
      expiringData14Days: undefined,
      searchData: undefined,
      guestDataExpiring: undefined,
      value: "",
    });
    if (index !== undefined) {
      axios
        .post("/networkoperatorconsole/venues/listVenueDetails", body, {
          headers: {},
        })
        .then((response) => {
          var sortedKeys = _(response.data[index]["Keypools"])
            .map(function (v, k) {
              // insert the key into the object
              return _.merge({}, v, { key: k });
            })
            .sortBy("DisplayOrder") // sort by name
            .value();
          this.setState({
            leasingAgents: [],
            venueAdmins: [],
          });
          this.setState({
            activeData:
              response.data[index]["RecentlyStartedSubscriptionsList_7day"],
            activeData1Day:
              response.data[index]["UpcomingSubscriptionsList_1day"],
            activeData7Day:
              response.data[index]["UpcomingSubscriptionsList_7day"],
            allActiveData: response.data[index]["Subscriptions"],
            expiringData:
              response.data[index][
                "UpcomingSubscriptionEndList_7day_unconfirmed"
              ],
            expiringData14Days:
              response.data[index]["UpcomingSubscriptionEndList_14day"],
            searchData: response.data[index]["UnitsAndSubscriptions"],
            UnitsBuildingFloorListSusbcribers:
              response.data[index]["Units-Building-Floor-List-Subscribers"],
            venueType: response.data[index]["VenueType"],
            venueInfo: response.data[index],
            keypoolID: Object.keys(response.data[index]["Keypools"])[0],
            servicePlans: response.data[index]["AvailableServicePlans"],
            propMgmtID:
              response.data[index]["PropertyManagementGroup"][
                "PropertyManagementGroupID"
              ],
            sharedSecret:
              response.data[index]["PropertyManagementGroup"]["SharedSecret"],
            keyPoolList: sortedKeys,
            venueAdminList: response.data[index]["VenueAdministrators"],
            venueLeasingAgentList: response.data[index]["LeasingAgents"],
            venueEquipList: response.data[index]["CoreEquipmentStatus"],
            currencyName: response.data[index]["ShoppingCart"]["Currencies"][0],
          });
          this.getAdmins();
          this.setState({ loadingVenueDetails: false });
          this.setState({ unitsArrayAll: undefined });
          let unitsArrayAll = [];
          let unitsArraySub = [];
          let unitsArrayGuest = [];
          let unitsArrayRoaming = [];
          let building;
          let floor;
          var obj = {};
          for (
            let i = 0;
            i <
            Object.keys(
              response.data[index]["Units-Building-Floor-List-Subscribers"]
            ).length;
            i++
          ) {
            obj[i] = {};
            let unitID = Object.keys(
              response.data[index]["Units-Building-Floor-List-Subscribers"]
            )[i];
            for (
              let j = 0;
              j <
              Object.keys(
                response.data[index]["Units-Building-Floor-List-Subscribers"][
                  unitID
                ]
              ).length;
              j++
            ) {
              obj[i][j] = {};
              building = Object.keys(
                response.data[index]["Units-Building-Floor-List-Subscribers"][
                  unitID
                ]
              )[j];
              for (
                var k = 0;
                k <
                Object.keys(
                  response.data[index]["Units-Building-Floor-List-Subscribers"][
                    unitID
                  ][building]
                ).length;
                k++
              ) {
                floor = Object.keys(
                  response.data[index]["Units-Building-Floor-List-Subscribers"][
                    unitID
                  ][building]
                )[k];
                obj[i][j][k] = {
                  UnitID: unitID,
                  Building: building,
                  Floor: floor,
                  Purpose:
                    response.data[index][
                      "Units-Building-Floor-List-Subscribers"
                    ][unitID][building][floor]["purpose"],
                  vlan: response.data[index][
                    "Units-Building-Floor-List-Subscribers"
                  ][unitID][building][floor]["vlan"],
                  address1:
                    response.data[index][
                      "Units-Building-Floor-List-Subscribers"
                    ][unitID][building][floor]["AddressLine1"],
                  address2:
                    response.data[index][
                      "Units-Building-Floor-List-Subscribers"
                    ][unitID][building][floor]["AddressLine2"],
                  city: response.data[index][
                    "Units-Building-Floor-List-Subscribers"
                  ][unitID][building][floor]["City"],
                  state:
                    response.data[index][
                      "Units-Building-Floor-List-Subscribers"
                    ][unitID][building][floor]["State"],
                  country:
                    response.data[index][
                      "Units-Building-Floor-List-Subscribers"
                    ][unitID][building][floor]["Country.ISO3166A3"],
                  postalcode:
                    response.data[index][
                      "Units-Building-Floor-List-Subscribers"
                    ][unitID][building][floor]["PostalCode"],
                  hasActiveSubscription:
                    response.data[index][
                      "Units-Building-Floor-List-Subscribers"
                    ][unitID][building][floor]["hasActiveOrFutureSubscription"],
                  subscriptions:
                    response.data[index][
                      "Units-Building-Floor-List-Subscribers"
                    ][unitID][building][floor]["Subscriptions"],
                  unitID_internal:
                    response.data[index][
                      "Units-Building-Floor-List-Subscribers"
                    ][unitID][building][floor]["UnitID_internal"],
                  realpageSyncDate:
                    response.data[index][
                      "Units-Building-Floor-List-Subscribers"
                    ][unitID][building][floor][
                      "RealPageSyncDateTimeUTC.ISO8601"
                    ],
                  smsCode:
                    response.data[index][
                      "Units-Building-Floor-List-Subscribers"
                    ][unitID][building][floor]["SMSCode"],
                  shoppingCartPriceAdjustment:
                    response.data[index][
                      "Units-Building-Floor-List-Subscribers"
                    ][unitID][building][floor]["ShoppingCartPriceAdjustment"],
                  allowOverlappingSubscriptionWiredUnit:
                    response.data[index][
                      "Units-Building-Floor-List-Subscribers"
                    ][unitID][building][floor][
                      "allowOverlappingSubscriptionWiredUnit"
                    ],
                  primaryUnitID_Internal:
                    response.data[index][
                      "Units-Building-Floor-List-Subscribers"
                    ][unitID][building][floor]["PrimaryUnitID_internal"],
                  selectedServicePlanPrice:
                    response.data[index][
                      "Units-Building-Floor-List-Subscribers"
                    ][unitID][building][floor]["ListBillingCyclePrice"],
                  enableShoppingCart:
                    response.data[index][
                      "Units-Building-Floor-List-Subscribers"
                    ][unitID][building][floor]["enableShoppingCart"],
                };
                unitsArraySub.push(obj[i][j][k]);
              }
            }
          }
          for (
            let i = 0;
            i <
            Object.keys(
              response.data[index]["Units-Building-Floor-List-GuestNetworks"]
            ).length;
            i++
          ) {
            obj[i] = {};
            let unitID = Object.keys(
              response.data[index]["Units-Building-Floor-List-GuestNetworks"]
            )[i];
            for (
              let j = 0;
              j <
              Object.keys(
                response.data[index]["Units-Building-Floor-List-GuestNetworks"][
                  unitID
                ]
              ).length;
              j++
            ) {
              let building = Object.keys(
                response.data[index]["Units-Building-Floor-List-GuestNetworks"][
                  unitID
                ]
              )[j];
              let floor = Object.keys(
                response.data[index]["Units-Building-Floor-List-GuestNetworks"][
                  unitID
                ][building]
              )[j];
              obj[i][j] = {
                UnitID: unitID,
                Building: building,
                Floor: floor,
                Purpose:
                  response.data[index][
                    "Units-Building-Floor-List-GuestNetworks"
                  ][unitID][building][floor]["purpose"],
                vlan: response.data[index][
                  "Units-Building-Floor-List-GuestNetworks"
                ][unitID][building][floor]["vlan"],
                address1:
                  response.data[index][
                    "Units-Building-Floor-List-GuestNetworks"
                  ][unitID][building][floor]["AddressLine1"],
                address2:
                  response.data[index][
                    "Units-Building-Floor-List-GuestNetworks"
                  ][unitID][building][floor]["AddressLine2"],
                city: response.data[index][
                  "Units-Building-Floor-List-GuestNetworks"
                ][unitID][building][floor]["City"],
                state:
                  response.data[index][
                    "Units-Building-Floor-List-GuestNetworks"
                  ][unitID][building][floor]["State"],
                country:
                  response.data[index][
                    "Units-Building-Floor-List-GuestNetworks"
                  ][unitID][building][floor]["Country.ISO3166A3"],
                postalcode:
                  response.data[index][
                    "Units-Building-Floor-List-GuestNetworks"
                  ][unitID][building][floor]["PostalCode"],
                hasActiveSubscription:
                  response.data[index][
                    "Units-Building-Floor-List-GuestNetworks"
                  ][unitID][building][floor]["hasActiveOrFutureSubscription"],
                subscriptions:
                  response.data[index][
                    "Units-Building-Floor-List-GuestNetworks"
                  ][unitID][building][floor]["Subscriptions"],
                unitID_internal:
                  response.data[index][
                    "Units-Building-Floor-List-GuestNetworks"
                  ][unitID][building][floor]["UnitID_internal"],
                realpageSyncDate:
                  response.data[index][
                    "Units-Building-Floor-List-GuestNetworks"
                  ][unitID][building][floor]["RealPageSyncDateTimeUTC.ISO8601"],
                smsCode:
                  response.data[index][
                    "Units-Building-Floor-List-GuestNetworks"
                  ][unitID][building][floor]["SMSCode"],
                shoppingCartPriceAdjustment:
                  response.data[index][
                    "Units-Building-Floor-List-GuestNetworks"
                  ][unitID][building][floor]["ShoppingCartPriceAdjustment"],
                allowOverlappingSubscriptionWiredUnit:
                  response.data[index][
                    "Units-Building-Floor-List-GuestNetworks"
                  ][unitID][building][floor][
                    "allowOverlappingSubscriptionWiredUnit"
                  ],
                primaryUnitID_Internal:
                  response.data[index][
                    "Units-Building-Floor-List-GuestNetworks"
                  ][unitID][building][floor]["PrimaryUnitID_internal"],
                selectedServicePlanPrice:
                  response.data[index][
                    "Units-Building-Floor-List-GuestNetworks"
                  ][unitID][building][floor]["ListBillingCyclePrice"],
                enableShoppingCart:
                  response.data[index][
                    "Units-Building-Floor-List-GuestNetworks"
                  ][unitID][building][floor]["enableShoppingCart"],
              };
              unitsArrayGuest.push(obj[i][j]);
            }
          }
          for (
            var i = 0;
            i <
            Object.keys(
              response.data[index]["Units-Building-Floor-List-RoamingNetworks"]
            ).length;
            i++
          ) {
            obj[i] = {};
            let unitID = Object.keys(
              response.data[index]["Units-Building-Floor-List-RoamingNetworks"]
            )[i];
            for (
              var j = 0;
              j <
              Object.keys(
                response.data[index][
                  "Units-Building-Floor-List-RoamingNetworks"
                ][unitID]
              ).length;
              j++
            ) {
              let building = Object.keys(
                response.data[index][
                  "Units-Building-Floor-List-RoamingNetworks"
                ][unitID]
              )[j];
              let floor = Object.keys(
                response.data[index][
                  "Units-Building-Floor-List-RoamingNetworks"
                ][unitID][building]
              );
              obj[i][j] = {
                UnitID: unitID,
                Building: building,
                Floor: floor,
                Purpose:
                  response.data[index][
                    "Units-Building-Floor-List-RoamingNetworks"
                  ][unitID][building][floor]["purpose"],
                vlan: response.data[index][
                  "Units-Building-Floor-List-RoamingNetworks"
                ][unitID][building][floor]["vlan"],
                address1:
                  response.data[index][
                    "Units-Building-Floor-List-RoamingNetworks"
                  ][unitID][building][floor]["AddressLine1"],
                address2:
                  response.data[index][
                    "Units-Building-Floor-List-RoamingNetworks"
                  ][unitID][building][floor]["AddressLine2"],
                city: response.data[index][
                  "Units-Building-Floor-List-RoamingNetworks"
                ][unitID][building][floor]["City"],
                state:
                  response.data[index][
                    "Units-Building-Floor-List-RoamingNetworks"
                  ][unitID][building][floor]["State"],
                country:
                  response.data[index][
                    "Units-Building-Floor-List-RoamingNetworks"
                  ][unitID][building][floor]["Country.ISO3166A3"],
                postalcode:
                  response.data[index][
                    "Units-Building-Floor-List-RoamingNetworks"
                  ][unitID][building][floor]["PostalCode"],
                hasActiveSubscription:
                  response.data[index][
                    "Units-Building-Floor-List-RoamingNetworks"
                  ][unitID][building][floor]["hasActiveOrFutureSubscription"],
                subscriptions:
                  response.data[index][
                    "Units-Building-Floor-List-RoamingNetworks"
                  ][unitID][building][floor]["Subscriptions"],
                unitID_internal:
                  response.data[index][
                    "Units-Building-Floor-List-RoamingNetworks"
                  ][unitID][building][floor]["UnitID_internal"],
                realpageSyncDate:
                  response.data[index][
                    "Units-Building-Floor-List-RoamingNetworks"
                  ][unitID][building][floor]["RealPageSyncDateTimeUTC.ISO8601"],
                smsCode:
                  response.data[index][
                    "Units-Building-Floor-List-RoamingNetworks"
                  ][unitID][building][floor]["SMSCode"],
                shoppingCartPriceAdjustment:
                  response.data[index][
                    "Units-Building-Floor-List-RoamingNetworks"
                  ][unitID][building][floor]["ShoppingCartPriceAdjustment"],
                allowOverlappingSubscriptionWiredUnit:
                  response.data[index][
                    "Units-Building-Floor-List-RoamingNetworks"
                  ][unitID][building][floor][
                    "allowOverlappingSubscriptionWiredUnit"
                  ],
                primaryUnitID_Internal:
                  response.data[index][
                    "Units-Building-Floor-List-RoamingNetworks"
                  ][unitID][building][floor]["PrimaryUnitID_internal"],
                selectedServicePlanPrice:
                  response.data[index][
                    "Units-Building-Floor-List-RoamingNetworks"
                  ][unitID][building][floor]["ListBillingCyclePrice"],
                enableShoppingCart:
                  response.data[index][
                    "Units-Building-Floor-List-RoamingNetworks"
                  ][unitID][building][floor]["enableShoppingCart"],
                selectedServicePlan:
                  response.data[index][
                   "Units-Building-Floor-List-RoamingNetworks"
                 ][unitID][building][floor]["ServicePlanID"],
              };
              unitsArrayRoaming.push(obj[i][j]);
            }
          }
          unitsArrayAll = unitsArraySub.concat(
            unitsArrayGuest,
            unitsArrayRoaming
          );
          this.setState({ unitsArrayAll: [...unitsArrayAll] });
          key = Object.keys(response.data[index]["Subscriptions"]);
          key.forEach((val) => {
            if (
              response.data[index]["Subscriptions"][key[iteration]][
                "StartDateTimeUTC.ISO8601"
              ] !== ""
            ) {
              lastName =
                response.data[index]["Subscriptions"][key[iteration]][
                  "SubscriberFamilyName"
                ];
              firstName =
                response.data[index]["Subscriptions"][key[iteration]][
                  "SubscriberGivenName"
                ];
              unitID =
                response.data[index]["Subscriptions"][key[iteration]]["UnitID"];
              unitID_internal =
                response.data[index]["Subscriptions"][key[iteration]][
                  "UnitID_internal"
                ];
              startDate =
                response.data[index]["Subscriptions"][key[iteration]][
                  "StartDateTimeUTC.ISO8601"
                ];
              contactpref_email =
                response.data[index]["Subscriptions"][key[iteration]][
                  "contactpref_email"
                ];
              contactpref_sms =
                response.data[index]["Subscriptions"][key[iteration]][
                  "contactpref_sms"
                ];
              phoneNumber =
                response.data[index]["Subscriptions"][key[iteration]][
                  "SubscriberPhoneNumber.E164"
                ];
              locale =
                response.data[index]["Subscriptions"][key[iteration]][
                  "SubscriberLocale"
                ];
              endDate =
                response.data[index]["Subscriptions"][key[iteration]][
                  "EndDateTimeUTC.ISO8601"
                ];
              floor =
                response.data[index]["Subscriptions"][key[iteration]][
                  "UnitFloor"
                ];
              building =
                response.data[index]["Subscriptions"][key[iteration]][
                  "UnitBuilding"
                ];
              email =
                response.data[index]["Subscriptions"][key[iteration]][
                  "SubscriberEmail"
                ];
              ssid =
                response.data[index]["Subscriptions"][key[iteration]]["ssid"];
              keyID = key[iteration];
              servicePlanID =
                response.data[index]["Subscriptions"][key[iteration]][
                  "ServicePlan"
                ]["ServicePlanID"];
              servicePlan =
                response.data[index]["Subscriptions"][key[iteration]][
                  "ServicePlan"
                ]["ServicePlanName"];
              vlan =
                response.data[index]["Subscriptions"][key[iteration]]["vlan"];
              residentStatus =
                response.data[index]["Subscriptions"][key[iteration]]["Status"];
              subscriptionDiscount =
                response.data[index]["Subscriptions"][key[iteration]][
                  "VenueUnitShoppingCartPriceAdjustment"
                ];
              venueDiscount =
                response.data[index]["Subscriptions"][key[iteration]][
                  "VenueShoppingCartPriceAdjustment"
                ];
              externalSubscriberID =
                response.data[index]["Subscriptions"][key[iteration]][
                  "externalSubscriberID"
                ];
              primaryUnitID_Internal =
                response.data[index]["Subscriptions"][key[iteration]][
                  "PrimaryUnitID_internal"
                ];
              selectedServicePlanPrice =
                response.data[index]["Subscriptions"][key[iteration]][
                  "ListBillingCyclePrice"
                ];
              getResults = () =>
                _.times(1, () => ({
                  firstName: firstName,
                  lastName: lastName,
                  unitID: unitID,
                  unitID_internal: unitID_internal,
                  startDate: startDate,
                  contactpref_email: contactpref_email,
                  contactpref_sms: contactpref_sms,
                  phoneNumber: phoneNumber,
                  locale: locale,
                  endDate: endDate,
                  floor: floor,
                  building: building,
                  title: firstName + " " + lastName,
                  description:
                    "Unit: " +
                    unitID +
                    "\r\n Building: " +
                    building +
                    ", Floor: " +
                    floor +
                    "\r\n" +
                    email,
                  keyid: keyID,
                  email: email,
                  ssid: ssid,
                  servicePlanID: servicePlanID,
                  servicePlan: servicePlan,
                  vlan: vlan,
                  status: residentStatus,
                  subscriptionDiscount: subscriptionDiscount,
                  venueDiscount: venueDiscount,
                  externalSubscriberID: externalSubscriberID,
                  primaryUnitID_Internal: primaryUnitID_Internal,
                  selectedServicePlanPrice: selectedServicePlanPrice,
                }));
              source = _.range(0, 3).reduce((memo1) => {
                const name = val;
                // eslint-disable-next-line no-param-reassign
                memo1 = {
                  name,
                  results: getResults(),
                };
                return memo1;
              }, {});
              source1.push(source);
              iteration = iteration + 1;
            }
            this.setState({
             hasUpdated: true
            })
          });
        })
        .catch((error) => {
          // this.setState({ showError: true });
          console.log(error);
          // this.setState({ errorMessage: "The listVenueDetails API timed out"})
          this.setState({ error: true });
        });
    }
  };
  getUserInfo = () => {
    const body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: sessionStorage.getItem("userEmail"),
    };
    axios
      .post("/networkoperatorconsole/subscribers/listSubscriberKeys", body, {
        headers: {},
      })
      .then((response) => {
        sessionStorage.setItem("firstName", response.data.GivenName);
        sessionStorage.setItem("lastName", response.data.FamilyName);
        sessionStorage.setItem("email", response.data.EmailAddress);
        sessionStorage.setItem(
          "phoneNumber",
          response.data["PhoneNumber.E164"]
        );
      })
      .catch((error) => {});
  };
  getPropertyManagementGroups = async() => {
    const data = {
      AccessToken: sessionStorage.getItem("AccessToken"),
    };
    await axios
      .post(
        "/networkoperatorconsole/venues/listPropertyManagementGroups",
        data,
        {
          headers: {},
        }
      )
      .then((response) => {
        this.setState({ propMgmtGroupList: response.data });
      })
      .catch((error) => {});
  };
  getAdmins = () => {
    this.setState({ staffArrayAll: undefined });
    let staffArrayAll = [];
    let staffArrayVA = [];
    let staffArrayLeasing = [];
    for (var i = 0; i < this.state.venueAdminList.length; i++) {
      staffArrayVA[i] = {
        [this.state.venueAdminList[i]]: "Venue Administrator",
      };
    }
    for (var j = 0; j < this.state.venueLeasingAgentList.length; j++) {
      staffArrayLeasing[j] = {
        [this.state.venueLeasingAgentList[j]]: "Leasing Agent",
      };
    }
    staffArrayAll = staffArrayVA.concat(staffArrayLeasing);
    this.setState({ staffArrayAll: [...staffArrayAll] });
  };
  handleResultSelect = (e, { result }) => {
    this.setState({ SubscriberGivenName: result.firstName });
    this.setState({ SubscriberFamilyName: result.lastName });
    this.setState({ UnitID: result.unitID });
    this.setState({ UnitID_internal: result.unitID_internal });
    this.setState({ StartDateTime: result.startDate });
    this.setState({ contactpref_email: result.contactpref_email });
    this.setState({ contactpref_sms: result.contactpref_sms });
    this.setState({ SubscriberPhoneNumber: result.phoneNumber });
    this.setState({ SubscriberLocale: result.locale });
    this.setState({ EndDateTime: result.endDate });
    this.setState({ UnitFloor: result.floor });
    this.setState({ UnitBuilding: result.building });
    this.setState({ SubscriberEmail: result.email });
    this.setState({ redirect: true });
    this.setState({ value: result.title });
    this.setState({ ssid: result.ssid });
    this.setState({ servicePlanID: result.servicePlanID });
    this.setState({ servicePlan: result.servicePlan });
    this.setState({ vlan: result.vlan });
    this.setState({ status: result.status });
    this.setState({
      modalModifyResident: !this.state.modalModifyResident,
    });
    this.setState({ subscriptionDiscount: result.subscriptionDiscount });
    this.setState({ venueDiscount: result.venueDiscount });
    this.setState({ externalSubscriberID: result.externalSubscriberID });
    this.setState({ primaryUnitID_Internal: result.primaryUnitID_Internal });
    this.setState({
      selectedServicePlanPrice: result.selectedServicePlanPrice,
    });
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  toggleModifyResident = () => {
    this.setState({
      modalModifyResident: !this.state.modalModifyResident,
    });
  };
  toggleModifyVenue = () => {
    this.setState({
      modalModifyVenue: !this.state.modalModifyVenue,
    });
  };
  refresh = () => {
    this.setState({ refreshed: true });
  };
  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value });
    let re = "";
    setTimeout(() => {
      this.setState({ results: "" });
      if (this.state.value.length < 1) return this.setState(initialState);
      re = new RegExp(_.escapeRegExp(this.state.value), "i");
      const isMatch = (result) =>
        re.test(result.title + result.description + result.email);
      this.setState({
        filteredResults: _.reduce(
          source1,
          (memo2, data, name) => {
            let results = _.filter(data.results, isMatch);
            if (results.length || results[0] !== undefined)
              memo2[name] = { name, results }; // eslint-disable-line no-param-reassign
            return memo2;
          },
          {}
        ),
      });
      this.setState({
        isLoading: false,
        results: this.state.filteredResults,
      });
    }, 300);
  };
  getOperatorInfo = () => {
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
    };
    this.setState({
      allowOverlapping: undefined,
    });
    axios
      .post("/networkoperatorconsole/equipment/listOperatorDetails", body, {
        headers: {},
      })
      .then(
        (response) => {
          this.setState({
            allowOverlapping:
              response.data.NetworkConfiguration
                .allowOverlappingSubscriptionWiredUnit,
          });
        },
        (error) => {
          console.log(
            "🚀 ~ file: Navigation.js:191 ~ Navigation ~ error",
            error
          );
        }
      );
  };
  modifyVenue = (venueName, key) => {
    this.setState({
      modalModifyVenue: !this.state.modalModifyVenue,
    });
    this.setState({
      venueID: key,
    });
    this.handleSelectChangeVenueFromList(venueName, key);
    // this.getVenues(key);
  };
  modifyVenueInfo = (
    address1,
    address2,
    phoneNumber,
    country,
    city,
    region,
    zipcode,
    email,
    countdown,
    durationResident,
    durationGuest,
    contactFirstName,
    contactLastName,
    minVlanRes,
    maxVlanRes,
    minVlanGuest,
    maxVlanGuest,
    minVlanRoaming,
    maxVlanRoaming,
    realpageSiteID,
    realpagePMCID,
    realpageSyncDate,
    venueType,
    shoppingCartPriceAdjustment,
    enableRealPageSync,
    enableShoppingCart,
    sendSubscriptionAddedEmail,
    sendSubscriptionEndEmail,
    allowOverlapping,
    trialCycleAdjustment,
    stripePublishableKey,
    stripeSecretKey,
    stripeWebhookSigningSecret,
    venueName,
    displayFCCLabel,
    typicalLatency
  ) => {
    if (address2 === undefined) {
      address2 = "";
    }
    this.setState({ loading: true });
    this.setState({ reloadVenueList: true });
    const updateVenueAddress = {
      VenueID: sessionStorage.getItem("venueID"),
      AddressLine1: address1,
      AddressLine2: address2,
      City: city,
      State: region,
      PostalCode: zipcode,
      "Country.ISO3166A3": country,
      AccessToken: sessionStorage.getItem("AccessToken"),
    };
    let updateVenue;
    typicalLatency !== ""
      ? (updateVenue = {
          VenueID: sessionStorage.getItem("venueID"),
          VenueName: venueName,
          AccessToken: sessionStorage.getItem("AccessToken"),
          WelcomeEmail_countdown_hours: countdown,
          DefaultSubscriptionDurationHours_GlobalWirelessPlusLocalWiredAccess:
            durationResident,
          DefaultSubscriptionDurationHours_LocalWirelessAccess: durationGuest,
          PrimaryContactGivenName: contactFirstName,
          PrimaryContactFamilyName: contactLastName,
          PrimaryContactEmailAddress: email,
          "PrimaryContactPhoneNumber.E164": phoneNumber,
          MinimumAutoGeneratedVLAN_GlobalWirelessPlusLocalWiredAccess:
            minVlanRes,
          MaximumAutoGeneratedVLAN_GlobalWirelessPlusLocalWiredAccess:
            maxVlanRes,
          MinimumAutoGeneratedVLAN_LocalWirelessAccess: minVlanGuest,
          MaximumAutoGeneratedVLAN_LocalWirelessAccess: maxVlanGuest,
          MinimumAutoGeneratedVLAN_GlobalWirelessRoaming: minVlanRoaming,
          MaximumAutoGeneratedVLAN_GlobalWirelessRoaming: maxVlanRoaming,
          RealPageSiteId: realpageSiteID,
          RealPagePMCId: realpagePMCID,
          "RealPageSyncDateTimeUTC.ISO8601": realpageSyncDate,
          VenueType: venueType,
          ShoppingCartPriceAdjustment:
            -Math.abs(shoppingCartPriceAdjustment) * 100,
          enableRealPageSync: enableRealPageSync,
          enableShoppingCart: enableShoppingCart,
          sendSubscriptionAddedEmail: sendSubscriptionAddedEmail,
          sendSubscriptionEndEmail: sendSubscriptionEndEmail,
          allowOverlappingSubscriptionWiredUnit: allowOverlapping,
          TrialCycleAdjustment: trialCycleAdjustment,
          StripePublishableKey: stripePublishableKey,
          Stripe_Secret_key: stripeSecretKey,
          Stripe_Webhook_signingsecret: stripeWebhookSigningSecret,
          FCCLabel: {
            displayLabel: displayFCCLabel,
            TypicalLatency: typicalLatency,
          },
        })
      : (updateVenue = {
          VenueID: sessionStorage.getItem("venueID"),
          VenueName: venueName,
          AccessToken: sessionStorage.getItem("AccessToken"),
          WelcomeEmail_countdown_hours: countdown,
          DefaultSubscriptionDurationHours_GlobalWirelessPlusLocalWiredAccess:
            durationResident,
          DefaultSubscriptionDurationHours_LocalWirelessAccess: durationGuest,
          PrimaryContactGivenName: contactFirstName,
          PrimaryContactFamilyName: contactLastName,
          PrimaryContactEmailAddress: email,
          "PrimaryContactPhoneNumber.E164": phoneNumber,
          MinimumAutoGeneratedVLAN_GlobalWirelessPlusLocalWiredAccess:
            minVlanRes,
          MaximumAutoGeneratedVLAN_GlobalWirelessPlusLocalWiredAccess:
            maxVlanRes,
          MinimumAutoGeneratedVLAN_LocalWirelessAccess: minVlanGuest,
          MaximumAutoGeneratedVLAN_LocalWirelessAccess: maxVlanGuest,
          MinimumAutoGeneratedVLAN_GlobalWirelessRoaming: minVlanRoaming,
          MaximumAutoGeneratedVLAN_GlobalWirelessRoaming: maxVlanRoaming,
          RealPageSiteId: realpageSiteID,
          RealPagePMCId: realpagePMCID,
          "RealPageSyncDateTimeUTC.ISO8601": realpageSyncDate,
          VenueType: venueType,
          ShoppingCartPriceAdjustment:
            -Math.abs(shoppingCartPriceAdjustment) * 100,
          enableRealPageSync: enableRealPageSync,
          enableShoppingCart: enableShoppingCart,
          sendSubscriptionAddedEmail: sendSubscriptionAddedEmail,
          sendSubscriptionEndEmail: sendSubscriptionEndEmail,
          allowOverlappingSubscriptionWiredUnit: allowOverlapping,
          TrialCycleAdjustment: trialCycleAdjustment,
          StripePublishableKey: stripePublishableKey,
          Stripe_Secret_key: stripeSecretKey,
          Stripe_Webhook_signingsecret: stripeWebhookSigningSecret,
          FCCLabel: {
            displayLabel: displayFCCLabel,
          },
        });
    Promise.all([
      axios.post(
        "/networkoperatorconsole/venues/updateVenueAddress",
        updateVenueAddress,
        { headers: {} }
      ),
      axios.post("/networkoperatorconsole/venues/configureVenue", updateVenue, {
        headers: {},
      }),
    ])
      .then(async ([res1, res2]) => {
        toast.success("Updated Venue!", {
          closeButton: false,
        });
        this.setState({ loading: false });
        this.toggleModifyVenue();
        this.setState({ reloadVenueList: false });
        this.updateName(
          sessionStorage.getItem("venueName"),
          sessionStorage.getItem("venueID")
        );
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          closeButton: false,
        });
        this.toggleModifyVenue();
        this.setState({ loading: false });
        this.setState({ reloadVenueList: false });
        this.updateName(
          sessionStorage.getItem("venueName"),
          sessionStorage.getItem("venueID")
        );
      });
  };
  handleSelectChangeVenue = (value) => {
    var venue = document.getElementsByClassName("venueSelectedResidentModal")[0]
      .value;
    this.setState({ selectedID: value[0] });
    this.updateName(venue, value[0]);
  };
  handleSelectChangeVenueFromList = (venueName, venueID) => {
    sessionStorage.setItem("venueName", venueName);
    sessionStorage.setItem("venueID", venueID);
    // this.getVenues(venueID);
    this.setState({ selectedID: venueID });
    this.updateName(venueName, venueID);
  };
  handleSwitchChange = (nr) => () => {
    this.setState({
      darkmode: !JSON.parse(this.state.darkmode),
    });
    localStorage.setItem("darkMode", !JSON.parse(this.state.darkmode));
    let element = document.getElementsByTagName("body")[0];
    element.setAttribute(
      "data-theme",
      "darkMode-" + !JSON.parse(this.state.darkmode)
    );
    this.getDisplayOptions();
  };
  render() {
    const { t } = this.props;
    return (
      <>
        {/* {this.state.showError ? 
					<MDBModal
						isOpen={true}
						size="md"
						className="form"
						centered
					>
					<MDBModalHeader className="error">
						<MDBIcon icon="exclamation-triangle" />Error
					</MDBModalHeader>
					<MDBModalBody className="error">
								<h4>An unknown error has occurred!</h4>
								<MDBBtn color="danger" onClick={() => window.location.reload(false)}>
									Try again
								</MDBBtn>
					</MDBModalBody>
					</MDBModal>
				:""} */}
        <ToastContainer
          hideProgressBar={true}
          newestOnTop={true}
          autoClose={10000}
        />
        {this.state.venueList === undefined ||
        this.state.propMgmtGroupList === undefined ? (
          <div className="overlay" id="overlay" style={{ display: "block" }}>
            <div className="overlayMessage">
              <div className="spinner-border spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div id="networkManagement">
              <MDBModal
                isOpen={this.state.modalModifyVenue}
                toggle={this.toggle}
                size="fluid"
                className="form"
                centered
              >
                <ModifyVenue
                  toggleModifyVenue={this.toggleModifyVenue}
                  modifyVenue={this.modifyVenue}
                  modifyVenueInfo={this.modifyVenueInfo}
                  venueID={this.state.venueID}
                  submitDelete={this.submitDelete}
                  sharedSecret={this.state.sharedSecret}
                  attributeForm={this.state.attributeForm}
                  venueInfo={this.state.venueInfo}
                  loadingVenueDetails={this.state.loadingVenueDetails}
                  getVenues={this.state.getVenues}
                  groupForm={this.state.groupForm}
                  keypoolForm={this.state.keypoolForm}
                  unitsArrayAll={this.state.unitsArrayAll}
                  keyPoolList={this.state.keyPoolList}
                  servicePlans={this.state.servicePlans}
                  staffArrayAll={this.state.staffArrayAll}
                  venueEquipList={this.state.venueEquipList}
                  getVenueDetails={this.getVenueDetails}
                  updateName={this.updateName}
                  keypoolID={this.state.keypoolID}
                  propMgmtID={this.state.propMgmtID}
                  loading={this.state.loading}
                  currencyName={this.state.currencyName}
                  optionTemplateVenues={this.state.optionTemplateVenues}
                  displayOptionIDGlobal={this.state.displayOptionID}
                  optionTemplateDisplayOptions={this.state.optionTemplateDisplayOptions}
                  venueType={this.state.venueType}
                  UnitsBuildingFloorListSusbcribers={this.props.UnitsBuildingFloorListSusbcribers}
                  hasUpdated={this.state.hasUpdated}
                />
              </MDBModal>
              <Navigation
                updateName={this.updateName}
                updateVenueList={this.updateVenueList}
                selectedName={this.state.selectedName}
                selectedID={this.state.selectedID}
                address1={this.state.address1}
                address2={this.state.address2}
                city={this.state.city}
                state={this.state.state}
                postalCode={this.state.postalCode}
                activeData={this.state.activeData}
                allActiveData={this.state.allActiveData}
                keypoolID={this.state.keypoolID}
                expirations={this.state.expiringData}
                guestData={this.state.guestData}
                guestDataExpiring={this.state.guestDataExpiring}
                venueData={this.state.venueData}
                venueAdmins={this.state.venueAdmins}
                leasingAgents={this.state.leasingAgents}
                tickets={this.state.tickets}
                loading={this.state.loading}
                venueType={this.state.venueType}
                buildings={this.state.buildings}
                updateSupport={this.getSupportDetails}
                coreEquipmentState={this.state.coreEquipmentState}
                cpeEquipmentState={this.state.cpeEquipmentState}
                venueList={this.state.venueList}
                availablePlans={this.state.venueServicePlans}
                UnitsBuildingFloorListSusbcribers={
                  this.state.UnitsBuildingFloorListSusbcribers
                }
                venueListForTable={this.state.venueListForTable}
                handleSwitchChange={this.handleSwitchChange}
                optionTemplateVenues={this.state.optionTemplateVenues}
                allowOverlapping={this.state.allowOverlapping}
                getDisplayOptions={this.getDisplayOptions}
                s3Directory={this.state.s3Directory}
                logo={this.state.logo}
                displayOptionID={this.state.displayOptionID}
                displayOptionIDGlobal={this.state.displayOptionID}
                optionTemplateDisplayOptions={
                  this.state.optionTemplateDisplayOptions
                }
                operatorName={this.state.operatorName}
                showSupport={this.state.showSupport}
                showDevices={this.state.showDevices}
                getVenues={this.getVenues}
                getVenuesList={this.getVenuesList}
                unitsArrayAll={this.state.unitsArrayAll}
                venueID={this.state.selectedID}
                venueInfo={this.state.venueInfo}
                loadingVenueDetails={this.state.loadingVenueDetails}
                hasUpdated={this.state.hasUpdated}
                keyPoolList={this.state.keyPoolList}
                servicePlans={this.state.servicePlans}
                staffArrayAll={this.state.staffArrayAll}
                venueEquipList={this.state.venueEquipList}
              />
              <div id="overlayResidentManagement">
                <div id="overlayMessage" className="overlayMessage">
                  {this.state.postMessage}
                </div>
              </div>
              <div className="mainDashboard">
                <MDBContainer fluid>
                  <MDBRow className="dashboardRow">
                    <MDBCol
                      sm="12"
                      md="12"
                      lg="5"
                      xl="4"
                      className="summaryCards"
                    >
                      <MDBCard>
                        <MDBCardBody>
                          <SummaryCardVenues
                            venueID={this.state.venueID}
                            getVenues={this.getVenues}
                            handleSelectChangeVenueFromList={
                              this.handleSelectChangeVenueFromList
                            }
                            venueList={this.state.venueList}
                            propMgmtGroupList={this.state.propMgmtGroupList}
                            sharedSecret={this.state.sharedSecret}
                            attributeForm={this.state.attributeForm}
                            venueInfo={this.state.venueInfo}
                            loadingVenueDetails={this.state.loadingVenueDetails}
                            groupForm={this.state.groupForm}
                            keypoolForm={this.state.keypoolForm}
                            unitsArrayAll={this.state.unitsArrayAll}
                            keyPoolList={this.state.keyPoolList}
                            servicePlans={this.state.servicePlans}
                            staffArrayAll={this.state.staffArrayAll}
                            venueEquipList={this.state.venueEquipList}
                            getVenueDetails={this.getVenueDetails}
                            updateName={this.updateName}
                            keypoolID={this.state.keypoolID}
                            propMgmtID={this.state.propMgmtID}
                            venueListForTable={this.state.venueListForTable}
                            optionTemplateVenues={
                              this.state.optionTemplateVenues
                            }
                            displayOptionIDGlobal={this.state.displayOptionID}
                            optionTemplateDisplayOptions={this.state.optionTemplateDisplayOptions}
                          />
                        </MDBCardBody>
                      </MDBCard>
                      <MDBCard>
                        <MDBCardBody>
                          <SummaryCardSubscribers
                            venueList={this.state.venueList}
                          />
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol>
                      <QuickLinks
                        keypoolID={this.state.keypoolID}
                        venueID={this.state.selectedID}
                        venueName={this.state.selectedName}
                        refresh={this.refresh}
                        recentResidents={this.state.activeData}
                        updateName={this.updateName}
                        venueAdmins={this.state.venueAdmins}
                        leasingAgents={this.state.leasingAgents}
                        venueType={this.state.venueType}
                        buildings={this.state.buildings}
                        availablePlans={this.state.venueServicePlans}
                        getVenueDetails={this.getVenueDetails}
                        allowOverlapping={this.state.allowOverlapping}
                        displayOptionIDGlobal={this.state.displayOptionID}
                        optionTemplateDisplayOptions={this.state.optionTemplateDisplayOptions}
                        optionTemplateVenues={this.state.optionTemplateVenues}
                      />
                      <MDBCard>
                        <MDBCardBody>
                          <h2>Venues</h2>
                          <div className="expandCollapse">
                            <p
                              onClick={() =>
                                this.setState({
                                  active: !this.state.active,
                                })
                              }
                            >
                              {this.state.active
                                ? t("recentservice.showless")
                                : t("recentservice.showmore")}
                            </p>
                          </div>
                          {this.state.venueListForTable !== undefined ? (
                            <div
                              className={
                                this.state.active
                                  ? "allVenuesExpanded"
                                  : "allVenues"
                              }
                            >
                              <ViewVenuesTable
                                venueList={this.state.venueListForTable}
                                refreshed={this.props.refreshed}
                                modifyVenue={this.modifyVenue}
                                
                              />
                            </div>
                          ) : (
                            <MDBSpinner small />
                          )}
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </div>
              <div className="footer">
                <MDBContainer fluid>
                  <MDBRow>
                    <MDBCol className="securedByVaultImage">
                      <LogoFooter darkMode={localStorage.getItem("darkMode")} />
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(Dashboard);
