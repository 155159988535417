import React, { Component } from "react";
import axios from "../../../axios";
import {
	MDBDropdown,
	MDBDropdownToggle,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBIcon,
	MDBCard,
	MDBCardBody,
	MDBRow,
	MDBCol,
	MDBModal,
	MDBModalBody,
	MDBModalHeader,
	MDBBtn,
	toast,
	ToastContainer,
} from "mdbreact";
import KeyManage from "../../../components/SubscriberMgmt/KeyInformation/KeyManage";
import ManageProfiles from "../../../components/SubscriberMgmt/ManageProfiles/ManageProfiles";
import { withTranslation } from "react-i18next";
import SelectSearch, { fuzzySearch } from "react-select-search";
import FAQ from "../../../components/VenueMgmt/Support/FAQs";
import _ from "lodash";
var QRCode = require("qrcode.react");
class KeyInformation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			keyListName: [],
			keyNameListArray: this.props.keyNameListArray,
			SessionList: this.props.SessionList,
			selectedName: null,
			selectedKeyName: null,
			selectedKeyID: this.props.selectedKeyID,
      showPassword: true,
			copyText: "Copy",
			modalManageKey: this.props.modalManageKey,
			venueListName: [],
			venueList: [],
			keys: this.props.keys,
			sortedKeys: this.props.sortedKeys,
			psk: null,
			newpsk: null,
			updatedpsk: null,
			addedOn: null,
			recent: null,
			venueName: this.props.venueName,
			loading: false,
			selectedNetworkIndex: 0,
			isWPA2: null,
			devicesForKey: null,
			token: this.props.token
		};
	}
	listKeys = () => {
		this.setState({ loading: true });

		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
		};

		let bodySessions = [];
		let iterationSessions = 0;
		let updatedSessions = [];

		let venueList = [];

		let keys;
  let keys1;
  let keys2 = [];
  let newKey = {};
  let updatedKeys = {};

		axios
			.post("/subscriberconsole/listKeys", body, {
				headers: {},
			})
			.then((response) => {
				this.setState({
					devices: [],
				});
				this.setState({ keys: [] });
				keys1 = response.data.KeysInKeyPools;
				venueList = Object.keys(response.data.KeysInKeyPools);


    if (Object.values(keys1).findIndex(item => item.ssid === 'Ubiquiti') === -1){

     _.merge(updatedKeys, keys1);
      for (let i = 0; i < Object.keys(keys1).length; i++){
       if(keys1[Object.keys(keys1)[i]].ssid === 'Ubiquiti'){
        // updatedKeys[Object.keys(keys1)[i]] = keys1[Object.keys(keys1)[i]]
         // console.log(updatedKeys)
        // console.log(Object.keys(keys1)[i])
        keys2 =  keys1[Object.keys(keys1)[i]]
       }
      }
     }else{
      for (let i = 0; i < Object.keys(keys1).length; i++){
       if(keys1[Object.keys(keys1)[i]].ssid === 'Ubiquiti'){
        updatedKeys[Object.keys(keys1)[i]] = keys1[Object.keys(keys1)[i]]
         // console.log(updatedKeys)
        // console.log(Object.keys(keys1)[i])
        keys2 =  keys1[Object.keys(keys1)[i]]
       }
      }
      for(let k=0; k < Object.keys(keys2).length; k++){
       if(Object.keys(keys2)[k] !== "DisplayOrder" && Object.keys(keys2)[k] !=="ssid"){
        // console.log(keys2[Object.keys(keys2)[k]]['psk'])
        let keytagname = Object.keys(keys2)[k];
        // console.log(keytagname)
        var randomString = Math.random().toString(36).slice(2)
        // let randomString = crypto.pbkdf2Sync(keys2[Object.keys(keys2)[k]]['psk'],'unifi', 4096, 32, "sha1").toString("hex");
        // console.log(randomString)
        const obj = keys2;
         Object.defineProperty(
           obj,
           keytagname,
           Object.getOwnPropertyDescriptor(obj, keytagname)
         );
         // delete keys2[keytagname];
       }
       const ssid = 'Ubiquiti'
       keys2 = {...keys2, ssid}
      }
      updatedKeys['Ubiquiti5g'] = keys2;
     }
    // console.log(updatedKeys)
    for(let m=0;m<Object.keys(updatedKeys).length;m++){
     let objectNumber = Object.keys(updatedKeys)[m]
     let unitIDforSSID;
     for(let n=0;n<Object.keys(updatedKeys[objectNumber]).length;n++){
       let indexNumber =  Object.keys(updatedKeys[objectNumber])[n]
       if(
        indexNumber!=='ssid'
       &&  indexNumber!=='DisplayOrder'
      ){
       let venueUnitPolicyIDforSSID = Object.keys(updatedKeys[objectNumber][indexNumber]['VenueUnitPolicies']);
       // console.log(venueUnitPolicyIDforSSID)
       console.log(updatedKeys[Object.keys(updatedKeys)[m]][indexNumber]['VenueUnitPolicies'][venueUnitPolicyIDforSSID])
       if(updatedKeys[Object.keys(updatedKeys)[m]][indexNumber]['VenueUnitPolicies'][venueUnitPolicyIDforSSID]!==undefined)
        unitIDforSSID = updatedKeys[Object.keys(updatedKeys)[m]][indexNumber]['VenueUnitPolicies'][venueUnitPolicyIDforSSID]['UnitID']
        // console.log(unitIDforSSID)
      }
      // console.log(unitIDforSSID)
      if(unitIDforSSID!==undefined&&updatedKeys[objectNumber]['ssid']==='Ubiquiti'){
       if(objectNumber==='Ubiquiti5g'){
        const ssid = unitIDforSSID + " 5G"
        const DisplayOrder = "10"
       updatedKeys[objectNumber] = {...updatedKeys[objectNumber], ssid, DisplayOrder}
       }else{

       const ssid = unitIDforSSID
       const DisplayOrder = "50"
       updatedKeys[objectNumber] = {...updatedKeys[objectNumber], ssid, DisplayOrder}
       }
      }
     }
    }
    console.log(updatedKeys)
				var sortedKeys = _(updatedKeys)
					.map(function (v, k) {
						// insert the key into the object
						return _.merge({}, v, { key: k });
					})
					.sortBy("DisplayOrder") // sort by name
					.value();

     
				this.setState({ venueList: Object.keys(updatedKeys) });
				this.setState({ keys: [updatedKeys] });
				this.setState({ sortedKeys: sortedKeys });
				this.setState({ venueName: this.state.venueList });
				this.setState({
					devices: response.data.AllDevices,
					deviceCountOffline: response.data.DeviceCountOffline,
					deviceCountOnline: response.data.DeviceCountOnline,
					messages: response.data.messages_from_venues,
					allData: response.data,
					token: response.data.SubscriberToken,
				});
				sessionStorage.setItem(
					"emailSubscriber",
					response.data.EmailAddress
				);
				sessionStorage.setItem(
					"phoneNumberSubscriber",
					response.data["PhoneNumber.E164"]
				);
				sessionStorage.setItem("firstName", response.data.GivenName);
				sessionStorage.setItem("lastName", response.data.FamilyName);
				let keyNameArray = [];
				let keyNameListArray = [];
				let keyNameListArrayAll = [];
				var obj = {};
				var obj_all = {};
				for (let i = 0; i < this.state.sortedKeys.length; i++) {
					keyNameArray.push(this.state.sortedKeys[i]);
					obj[i] = {};
					let keyNameList = Object.keys(this.state.sortedKeys[i]);
     // console.log(keyNameList)
					for (
						let j = 0;
						j < Object.keys(this.state.sortedKeys[i]).length;
						j++
					) {
						let keyPoolID = keyNameList[j];
						if (
							this.state.sortedKeys[i][keyPoolID]["KeyTag"] !==
							undefined
						) {
       // console.log(this.state.sortedKeys[i][keyPoolID])
							obj[i][keyPoolID] = 0;
							obj[i][keyPoolID] =
								this.state.sortedKeys[i][keyPoolID]["KeyTag"];
        obj_all[i] =
								this.state.sortedKeys[i][keyPoolID];
       obj_all[i].keyTag = 
       keyPoolID
							obj_all[i].network =
								this.state.sortedKeys[i]["ssid"];

							obj_all[i].selectedNetworkIndex = i;

							let venueUnitPolicyID = Object.keys(
								this.state.sortedKeys[i][keyPoolID][
									"VenueUnitPolicies"
								]
							);
							obj_all[i].venueID =
								this.state.sortedKeys[i][keyPoolID][
									"VenueUnitPolicies"
								][venueUnitPolicyID[0]]["VenueID"];
							obj_all[i].servicePlanID =
								this.state.sortedKeys[i][keyPoolID][
									"VenueUnitPolicies"
								][venueUnitPolicyID[0]]["ServicePlan"][
									"ServicePlanID"
								];
							// this.setState({
							// 	paymentFailed:
							// 		this.state.sortedKeys[i][keyPoolID][
							// 			"VenueUnitPolicies"
							// 		][venueUnitPolicyID[0]]["paymentFailed"],
							// });
							if(!this.state.sortedKeys[i][keyPoolID]["wpa2enterprise_auth"]){
								this.setState({
									paymentFailed:
										this.state.sortedKeys[i][keyPoolID][
											"VenueUnitPolicies"
										][venueUnitPolicyID[0]]["paymentFailed"],
								});
								sessionStorage.setItem(
									"venueIDSubscriber",
									this.state.sortedKeys[i][keyPoolID][
										"VenueUnitPolicies"
									][venueUnitPolicyID[0]]["VenueID"]
								);
								sessionStorage.setItem(
									"stripeClientSecretSubscriber",
									this.state.sortedKeys[i][keyPoolID][
										"VenueUnitPolicies"
									][venueUnitPolicyID[0]]["StripeClientSecret"]
								);
								sessionStorage.setItem(
									"unitID_internalSubscriber",
									this.state.sortedKeys[i][keyPoolID][
										"VenueUnitPolicies"
									][venueUnitPolicyID[0]]["UnitID_internal"]
								);
								sessionStorage.setItem(
									"servicePlanIDSubscriber",
									this.state.sortedKeys[i][keyPoolID][
										"VenueUnitPolicies"
									][venueUnitPolicyID[0]]["ServicePlan"][
										"ServicePlanID"
									]
								);
								sessionStorage.setItem(
									"servicePlanNameSubscriber",
									this.state.sortedKeys[i][keyPoolID][
										"VenueUnitPolicies"
									][venueUnitPolicyID[0]]["ServicePlan"][
										"ServicePlanName"
									]
								);
								sessionStorage.setItem(
									"servicePlanNameSubscriber",
									this.state.sortedKeys[i][keyPoolID][
										"VenueUnitPolicies"
									][venueUnitPolicyID[0]]["ServicePlan"][
										"ServicePlanName"
									]
								);
								sessionStorage.setItem(
									"address",
									this.state.sortedKeys[i][keyPoolID][
										"VenueUnitPolicies"
									][venueUnitPolicyID[0]]["AddressLine1"]
								);
								sessionStorage.setItem(
									"city",
									this.state.sortedKeys[i][keyPoolID][
										"VenueUnitPolicies"
									][venueUnitPolicyID[0]]["City"]
								);
								sessionStorage.setItem(
									"state",
									this.state.sortedKeys[i][keyPoolID][
										"VenueUnitPolicies"
									][venueUnitPolicyID[0]]["State"]
								);
								sessionStorage.setItem(
									"postalcode",
									this.state.sortedKeys[i][keyPoolID][
										"VenueUnitPolicies"
									][venueUnitPolicyID[0]]["PostalCode"]
								);
								sessionStorage.setItem(
									"unit",
									this.state.sortedKeys[i][keyPoolID][
										"VenueUnitPolicies"
									][venueUnitPolicyID[0]]["UnitID_internal"]
								);
								sessionStorage.setItem(
									"unitName",
									this.state.sortedKeys[i][keyPoolID][
										"VenueUnitPolicies"
									][venueUnitPolicyID[0]]["UnitID"]
								);
								sessionStorage.setItem(
									"dateStart",
									this.state.sortedKeys[i][keyPoolID][
										"VenueUnitPolicies"
									][venueUnitPolicyID[0]][
										"StartDateTimeUTC.ISO8601"
									]
								);
								sessionStorage.setItem(
									"logoURL",
									this.state.sortedKeys[i][keyPoolID][
										"VenueUnitPolicies"
									][venueUnitPolicyID[0]][
										"DisplayOptions"
									]["LogoPNG.URL"]
								);
								sessionStorage.setItem(
									"supportEmail",
									this.state.sortedKeys[i][keyPoolID][
										"VenueUnitPolicies"
									][venueUnitPolicyID[0]][
										"DisplayOptions"
									]["VenueSupportEmailAddress"]
								);
								sessionStorage.setItem(
									"supportPhone",
									this.state.sortedKeys[i][keyPoolID][
										"VenueUnitPolicies"
									][venueUnitPolicyID[0]][
										"DisplayOptions"
									]["VenueSupportPhoneNumber.E164"]
								);
							}
						}
					}
				}
    // console.log(obj);
				keyNameListArrayAll.push(obj_all);
				keyNameListArray.push(obj);

				this.setState({ keyNameListArray: keyNameListArray });
				this.setState({ keyNameListArrayAll: keyNameListArrayAll });
				this.setState({ keyNameArray: keyNameArray });
				const sessionList = sortedKeys;
				let SessionListID = null;

				bodySessions.push(sessionList);
				bodySessions.forEach((val) => {
					SessionListID = Object.keys(bodySessions[0]);
				});
				if (this.state.SessionList !== []) {
					this.setState({ SessionList: [] });
				}
				SessionListID.forEach((val, index) => {
					updatedSessions = bodySessions.map((sessionList) => {
						return {
							...sessionList[SessionListID[iterationSessions]],
						};
					});
					if (updatedSessions[0] !== undefined) {
						this.setState({
							SessionList: [
								...this.state.SessionList,
								updatedSessions,
							],
						});
						iterationSessions = iterationSessions + 1;
					}
				});

				if (this.state.keys !== undefined) {
					this.setState({ loading: false });
				}
				if(this.state.keyNameArray.length!==0){
				this.getServicePlans();
				}
			})
			.catch((error) => {
				this.setState({ loading: false });
				this.setState({ showError: true });
				this.setState({ error: error });
			});
	};
	updateName = (value, index) => {
		this.setState({ selectedName: value });
		this.setState({ selectedID: index });
	};
	updateNameProfile = (value, index, keyInfo, newpassword, tag) => {
		this.listKeys();
		this.setState({ selectedKeyName: value });
		this.setState({ selectedKeyID: undefined });
		this.setState({ keyInfo: keyInfo });
		this.setState({ psk: newpassword });
		this.setState({ newpsk: newpassword + "." + tag });
		this.setState({ updatedpsk: newpassword + "." + tag });
		this.setState({ token: tag });
	};
	updateNameKey = (value, index, selectedNetworkIndex) => {
		this.setState({ selectedKeyName: value });
		this.setState({ selectedKeyID: index });
		this.setState({
			psk: this.state.sortedKeys[selectedNetworkIndex !== undefined ? selectedNetworkIndex : this.state.selectedNetworkIndex][index]["psk"],
		});
		this.setState({
			addedOn:
				this.state.sortedKeys[selectedNetworkIndex !== undefined ? selectedNetworkIndex : this.state.selectedNetworkIndex][index][
					"dateAddedUTC.ISO8601"
				],
		});
		this.setState({
			recent: this.state.sortedKeys[selectedNetworkIndex !== undefined ? selectedNetworkIndex : this.state.selectedNetworkIndex][index]["mostRecentUseUTC.ISO8601"],
		});
		this.setState({
			isWPA2: this.state.sortedKeys[selectedNetworkIndex !== undefined ? selectedNetworkIndex : this.state.selectedNetworkIndex][index]["wpa2enterprise_auth"],
		});
		this.setState({
			devicesForKey:
				this.state.sortedKeys[selectedNetworkIndex !== undefined ? selectedNetworkIndex : this.state.selectedNetworkIndex][index][
					"Devices"
				],
		});
	};
	updateNameNetwork = (value, index) => {
		let keyNameListArray = Object.entries(this.state.keyNameListArray[0][index]);
		this.setState({ selectedName: value });
		this.setState({ selectedNetworkIndex: index });
		this.updateNameKey(keyNameListArray[0][1], keyNameListArray[0][0], index);
	};
	updatePassword = (value, index, password, tag, selectedNetworkIndex) => {
		if (tag !== undefined) {
			this.setState({ psk: password + "." + tag });
			this.setState({ updatedpsk: password + "." + tag });
		} else {
			this.setState({ psk: password });
			this.setState({ updatedpsk: password });
		}
		this.setState({ newpsk: password });
		this.setState({ token: tag });
		this.listKeys();
	};
	togglePassword = () => {
		this.setState({ showPassword: !this.state.showPassword });
	};
	copyPassword = () => {
		const { t } = this.props;
		var copyText = document.getElementById("passwordHidden");
		var textArea = document.createElement("textarea");
		textArea.value = copyText.textContent;
		document.body.appendChild(textArea);
		textArea.select();
		document.execCommand("Copy");
		this.setState({ copyText: "Copied" });
		textArea.remove();
		toast.success(t("profiledetails.passwordcopied"), {
			closeButton: false,
		});
	};
	setPassword = (newpassword, password, token) => {
		let scrubbedPassword = newpassword.replace(/\./g,'')
		// console.log("🚀 ~ file: KeyInformation.js:245 ~ KeyInformation ~ newpassword:", scrubbedPassword)
		this.setState({ updatedpsk: scrubbedPassword + "." + token });
		this.setState({ newpsk: scrubbedPassword });
		this.setState({ token: token });
	};
	submitEditPassword = (currentKeyID) => {
		const { t } = this.props;
		if (this.state.newpsk !== null) {
			let body = {
				AccessToken: sessionStorage.getItem("AccessToken"),
				CurrentKeyID: currentKeyID,
				UserPartForNewKey: this.state.newpsk
			};
			axios
				.post("/subscriberconsole/resetKey", body, {
					headers: {},
				})
				.then((response) => {
					this.setState({
						modalEditPassword: !this.state.modalEditPassword,
					});
					toast.success(t("profiledetails.passwordupdated"), {
						closeButton: false,
					});
					this.listKeys();
					this.setState({ 
						psk: this.state.newpsk + "." + this.state.token,
						updatedpsk: this.state.newpsk + "." + this.state.token,
						selectedKeyID: undefined
					});
				})
				.catch((error) => {
					this.setState({ error: true });
					toast.error(t("profiledetails.passwordnotupdated"), {
						closeButton: false,
					});
				});
		} else {
			toast.success("We encountered an error. Please reload the page and try again.", {
				closeButton: false,
			});
		}
	};
	downloadQR = () => {
		const canvas = document.getElementById("qrCode");
		const pngUrl = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
		let downloadLink = document.createElement("a");
		downloadLink.href = pngUrl;
		downloadLink.download = "qrcode.jpg";
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};
	toggleManageKey = () => {
		this.setState({
			modalManageKey: !this.state.modalManageKey,
		});
	};
	toggleManageProfiles = () => {
		this.setState({
			modalManageProfiles: !this.state.modalManageProfiles,
		});
	};
	toggleEditPassword = () => {
		this.setState({
			password: this.props.password,
			modalEditPassword: !this.state.modalEditPassword,
		});
	};
	validateForm() {
		if(this.state.newpsk!==null){
        	return this.state.newpsk.length >= 1;
        }else{
        	return true;
        }
    }
    toggleFAQs = () => {
     this.setState({
       modalFAQ: !this.state.modalFAQ,
     });
   };
	render() {
		const { t } = this.props;
		let venueName;
		let ssidName;
		let firstKeyID;
		let firstKeyName;
		let firstPSK;
		let dateAddedOn;
		let recentDateUsed = [];
		let isWPA2;
		let devices;
		let keyNameListArray = Object.entries(this.state.keyNameListArray[0][this.state.selectedNetworkIndex]);
		let keyNameListArray1 = this.state.keyNameListArray;
		let keyNameListArrayAll = this.state.keyNameListArrayAll === undefined ? this.props.keyNameListArrayAll : this.state.keyNameListArrayAll;
  // console.log(keyNameListArray)
		if (this.state.venueName[0] !== undefined && this.state.sortedKeys !== undefined) {
			venueName = this.state.venueName;
			ssidName = this.state.sortedKeys[0]["ssid"];
			firstKeyID = Object.keys(this.state.sortedKeys[0])[2];
			firstKeyName = this.state.sortedKeys[0][firstKeyID]["KeyTag"];
			firstPSK = this.state.sortedKeys[0][firstKeyID]["psk"];
			dateAddedOn = this.state.sortedKeys[0][firstKeyID]["dateAddedUTC.ISO8601"];
			recentDateUsed = this.state.sortedKeys[0][firstKeyID]["mostRecentUseUTC.ISO8601"];
			isWPA2 = this.state.sortedKeys[0][firstKeyID]["wpa2enterprise_auth"];
			devices = this.state.sortedKeys[0][firstKeyID]["Devices"];
		}
		isWPA2 = this.state.isWPA2 !== null ? this.state.isWPA2 : isWPA2;
		let password;
		if (this.state.psk !== null) {
			password = this.state.psk;
		} else {
			password = firstPSK;
		}
		return (
			<>
				<MDBModal isOpen={this.state.modalManageKey} toggle={this.toggle} size="lg" className="form editProfile">
					<KeyManage
						venueName={this.state.venueName[this.state.selectedNetworkIndex]}
						toggleManageKey={this.toggleManageKey}
						ssidName={this.state.selectedName === null ? ssidName : this.state.selectedName}
						keyInfo={this.state.keys}
						sortedKeys={this.state.sortedKeys}
						keyID={this.state.selectedKeyID !== undefined ? this.state.selectedKeyID : firstKeyID}
						submitHandler={this.submitHandler}
						downloadQR={this.downloadQR}
						copyPassword={this.copyPassword}
						keyNameListArray={keyNameListArray}
						firstKeyName={firstKeyName}
						updateName={this.updateName}
						updateNameKey={this.updateNameKey}
						updatePassword={this.updatePassword}
						updateNameProfile={this.updateNameProfile}
						selectedKeyName={this.state.selectedKeyName}
						password={this.state.psk}
						token={this.state.token}
						firstPSK={firstPSK}
						listKeys={this.listKeys}
						addedOn={this.state.addedOn}
						dateAddedOn={dateAddedOn}
						recent={this.state.recent}
						recentDateUsed={recentDateUsed}
						tag={this.state.tag}
						selectedName={ssidName === undefined ? this.state.selectedNam : ssidName}
						devices={this.state.devicesForKey !== null ? this.state.devicesForKey : devices}
						SessionList={this.props.SessionList}
						updateNameNetwork={this.updateNameNetwork}
						isWPA2={this.state.isWPA2 !== null ? this.state.isWPA2 : isWPA2}
					/>
				</MDBModal>
				<ToastContainer hideProgressBar={true} newestOnTop={true} autoClose={10000} />
				<MDBModal isOpen={this.state.modalEditPassword} toggle={this.toggle} size="lg" className="form">
					<MDBModalHeader toggle={this.toggleEditPassword}>
						<h4>{t("profiledetails.editpasswordtitle")}</h4>
					</MDBModalHeader>
					<MDBModalBody>
						<MDBRow>
							<MDBCol col="6">
								<p>{t("profiledetails.warning")}</p>
								<p>{t("profiledetails.warningdesc")}</p>
								<div className="form-group row">
									<div className="col-sm-2">
										<p>{t("profiledetails.profile")}</p>
									</div>
									<div className="col-sm-6">{this.state.selectedKeyName === null ? firstKeyName : this.state.selectedKeyName}</div>
								</div>
								<div className="form-group row">
									<div className="col-sm-2">
										<p>{t("profiledetails.network")}</p>
									</div>
									<div className="col-sm-6">{this.state.selectedName === null ? ssidName : this.state.selectedName}</div>
								</div>
                <p className="wifiDisclaimer">Enter a new Personal Token below. Your Wi-Fi password is the combination of your personal token and your unique ID. For security purposes, the unique ID cannot be modified.</p>
                <label
                  htmlFor="defaultFormLoginEmailEx"
                  className="black-text passwordLabel"
                >
                  Personal Token
								</label>
								<input
									id="editpassword"
									className="form-control"
									onChange={(e) =>
										this.setPassword(
											e.target.value,
											this.state.updatedpsk === null ? firstPSK.split(".")[1] === undefined ? firstPSK + "." + this.state.token : firstPSK + "." + firstPSK.split(".")[1] : e.target.value + "." + this.state.token,
											this.state.updatedpsk === null ? firstPSK.split(".")[1] === undefined ? this.state.token : firstPSK.split(".")[1] : this.state.token
										)
									}
									name="password"
									value={this.state.updatedpsk === null ? firstPSK.split(".")[0] : this.state.updatedpsk.split(".")[0]}
								/>
								<span className="password_subscriber">.{this.state.token === undefined ? firstPSK.split(".")[1] : this.state.token}</span>
								<div className="invalid-feedback">Please provide a valid password.</div>
								<br />
								<br />
                <p>Your Wi-Fi password</p>
								<input
									id="editpassword2"
									className="form-control"
									onChange={this.changeHandler}
									name="password"
									value={this.state.updatedpsk === null ? 
											firstPSK.split(".")[1] === undefined ? firstPSK + "." + this.state.token : firstPSK : this.state.updatedpsk.split(".")[0] + "." + this.state.token}
								/>
							</MDBCol>
						</MDBRow>
						<div className="text-right submitButton">
							<MDBBtn
								color="danger"
								disabled={!this.validateForm()}
								onClick={(e) => this.submitEditPassword(this.state.selectedKeyID !== undefined ? this.state.selectedKeyID : firstKeyID)}
							>
								{t("profiledetails.submit")}
							</MDBBtn>
							<MDBBtn color="danger" onClick={this.toggleEditPassword}>
								{t("profiledetails.cancel")}
							</MDBBtn>
						</div>
					</MDBModalBody>
				</MDBModal>
    <MDBModal
            isOpen={this.state.modalFAQ}
            toggle={this.toggleFAQs}
            size="lg"
            className="form"
            centered
          >
            <FAQ toggleFAQs={this.toggleFAQs} />
          </MDBModal>
				<div className="keyInformation">
					<div className="ctaButton" onClick={this.toggleManageProfiles}>
						<h2>
							<MDBIcon icon="wifi" />
							{t("profile.title")}
						</h2>
					</div>
					<MDBModal isOpen={this.state.modalManageProfiles} toggle={this.toggle} size="fluid" className="form">
						<ManageProfiles
							toggleManageProfiles={this.toggleManageProfiles}
							keyInfo={this.state.keys}
							sortedKeys={this.state.sortedKeys}
							ssidName={this.state.selectedName === null ? ssidName : this.state.selectedName}
							password={this.state.psk}
							token={this.state.token}
							keyID={this.state.selectedKeyID !== undefined ? this.state.selectedKeyID : firstKeyID}
							updateNameKey={this.updateNameKey}
							venueName={venueName}
							keyNameListArray={keyNameListArray1}
							toggleManageKey={this.toggleManageKey}
							updateName={this.updateName}
							selectedKeyName={this.state.selectedKeyName}
							devices={this.state.devicesForKey !== null ? this.state.devicesForKey : devices}
							SessionList={this.props.SessionList}
							selectedNetworkIndex={this.state.selectedNetworkIndex}
							keyNameListArrayAll={keyNameListArrayAll}
							updateNameProfile={this.updateNameProfile}
							updateNameNetwork={this.updateNameNetwork}
							listKeys={this.listKeys}
						/>
					</MDBModal>
					{Object.keys(this.props.SessionList).length > 0 ? (
						<MDBCard>
							<MDBCardBody>
								<MDBRow>
									<MDBCol sm="8" md="8" lg="8">
										<MDBRow className="keyInfo">
											<MDBCol sm="5" md="5" lg="6">
												<h3>
													<strong>{t("profile.profile")}</strong>
												</h3>
											</MDBCol>
											<MDBCol sm="7" md="7" lg="6">
												<MDBDropdown>
													<MDBDropdownToggle nav caret>
														<span className="mr-2" onClick={this.click}>
															{this.state.selectedKeyName === null ? firstKeyName : this.state.selectedKeyName}
														</span>
													</MDBDropdownToggle>
													<MDBDropdownMenu>
														{keyNameListArray.map((value, index) => {
															return (
																<MDBDropdownItem
																	onClick={(e) => this.updateNameKey(keyNameListArray[index][1], keyNameListArray[index][0])}
																>
																	{keyNameListArray[index][1]}
																</MDBDropdownItem>
															);
														})}
													</MDBDropdownMenu>
												</MDBDropdown>
											</MDBCol>
										</MDBRow>
										<MDBRow className="keyInfo">
											<MDBCol sm="5" md="5" lg="6">
												<h3>
													<strong>{t("profile.network")}</strong>
												</h3>
											</MDBCol>
											<MDBCol sm="7" md="7" lg="6">
												<MDBDropdown>
													<MDBDropdownToggle nav caret>
														<span className="mr-2">{this.state.selectedName === null ? ssidName : this.state.selectedName}</span>
													</MDBDropdownToggle>
													<MDBDropdownMenu>
														{this.props.SessionList.map((value, index) => {
															return (
																<MDBDropdownItem
																	onClick={(e) => this.updateNameNetwork(this.props.SessionList[index][0]["ssid"], index)}
																>
																	{this.props.SessionList[index][0]["ssid"]}
																</MDBDropdownItem>
															);
														})}
													</MDBDropdownMenu>
												</MDBDropdown>
											</MDBCol>
										</MDBRow>
										<MDBRow className="keyInfo">
											<MDBCol sm="5" md="5" lg="6">
												<h3>
													<strong>{t("profile.password")} </strong>
													{/* {this.state.showPassword !== true ? (
														<MDBIcon icon="eye" onClick={this.togglePassword} />
													) : (
														<MDBIcon icon="eye-slash" onClick={this.togglePassword} />
													)} */}
													<MDBIcon icon="copy" onClick={this.copyPassword} />
													{isWPA2 === false ? <MDBIcon icon="edit" onClick={this.toggleEditPassword} /> : ""}
												</h3>
											</MDBCol>
											<MDBCol sm="7" md="7" lg="6">
												<MDBRow>
													<MDBCol lg="12">
														<span id="password">
															{this.state.showPassword === true ? (password === undefined ? firstPSK : password) : "************"}
														</span>
														<span id="passwordHidden">{password === undefined ? firstPSK : password}</span>
													</MDBCol>
													<MDBCol lg="2"></MDBCol>
												</MDBRow>
											</MDBCol>
										</MDBRow>
										<MDBRow className="keyInfo">
                      {" "}
                      <MDBCol md="12" lg="12">
                        <a
                          href="#link"
                          className="moreDetails"
                          onClick={this.toggleManageKey}
                        >
                          {t("profile.moredetails")}
                          <MDBIcon icon="caret-right" />
                        </a>
                        <br />
                        <a
                          href="#link"
                          className="moreDetails"
                          onClick={this.toggleFAQs}
                        >
                          Having Trouble Connecting?
                          <MDBIcon icon="caret-right" />
												</a>
                      </MDBCol>{" "}
                      <MDBCol md="5" lg="5"></MDBCol>
										</MDBRow>
									</MDBCol>
									<MDBCol sm="4" md="4" lg="4">
											<div className="qrCode">
												{this.state.psk === null ? (
													<>
														<QRCode
															id="qrCode"
															value={
																"WIFI:T:WPA;S:" +
																(this.state.selectedName === null ? ssidName : this.state.selectedName) +
																";P:" +
																firstPSK +
																";H:true;"
															}
															size={100}
														/>
														<br />
													</>
												) : (
													<>
														<QRCode
															id="qrCode"
															value={
																"WIFI:T:WPA;S:" +
																(this.state.selectedName === null ? ssidName : this.state.selectedName) +
																";P:" +
																this.state.psk +
																";H:true;"
															}
															size={100}
														/>
														<br />
													</>
												)}
												<p>{t("profile.scan")}</p>
												<MDBIcon icon="download" onClick={this.downloadQR} />
											</div>
									</MDBCol>
								</MDBRow>
							</MDBCardBody>
						</MDBCard>
					) : (
						<MDBCard style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
							<MDBCardBody className="messageCardBody1" style={{ flex: "unset" }}>
								<h4 style={{ color: "#BBB" }}>{t("profile.nokeys")}</h4>
							</MDBCardBody>
						</MDBCard>
					)}
				</div>
			</>
		);
	}
}
export default withTranslation()(KeyInformation);