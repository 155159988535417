/* Find Deals screen */
import React, { Component, Suspense } from "react";
import axios from "../../axios";
import queryString from "query-string";
import Footer from "../../components/Common/Footer";
let CreateAccount2Component = React.lazy(() =>
  import(
    "../../brands/" +
      sessionStorage.getItem("customBrand") +
      "/components/CreateAccount/CreateAccountP2"
  )
);
const parsed = queryString.parse(window.location.search);
const root = document.documentElement;
root?.style.setProperty(
 "--background-color",
 sessionStorage.getItem("background-color")
);
root?.style.setProperty(
 "--modal-background-color",
 sessionStorage.getItem("modal-background-color")
);
root?.style.setProperty(
 "--primary-text-color",
 sessionStorage.getItem("primary-text-color")
);
root?.style.setProperty("--base-color", sessionStorage.getItem("base-color"));
root?.style.setProperty(
 "--secondary-color",
 sessionStorage.getItem("secondary-color")
);
root?.style.setProperty(
 "--secondary-text-color",
 sessionStorage.getItem("secondary-text-color")
);
root?.style.setProperty(
 "--border-color",
 sessionStorage.getItem("border-color")
);
root?.style.setProperty(
 "--base-color-rgb",
 sessionStorage.getItem("base-color-rgb")
);
root?.style.setProperty("--link-color", sessionStorage.getItem("link-color"));
root?.style.setProperty(
 "--button-color",
 sessionStorage.getItem("button-color")
);
root?.style.setProperty("--button-text-color", "#FFF");
root?.style.setProperty(
 "--banner-color-1",
 sessionStorage.getItem("banner-color-1")
);
root?.style.setProperty(
 "--banner-color-2",
 sessionStorage.getItem("banner-color-2")
);
class CreateAccount1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tandc: false,
      modalTandC: false,
      loadingTandC: false,
    };
  }
  componentDidMount() {
    this.getTandC();
  }
  getTandC = () => {
    let locale;
    if (parsed["locale"]) {
      locale = parsed["locale"];
    } else {
      if (
        sessionStorage.getItem("i18nextLng") === "en_us" ||
        sessionStorage.getItem("i18nextLng") === "en" ||
        sessionStorage.getItem("i18nextLng") === "en-US"
      ) {
        locale = "en-us";
      }
    }
    let data = {
      locale: locale,
    };
    this.setState({ loadingTandC: true });
    axios
      .post("/networkoperatorconsole/equipment/listOperatorDetails", data, {
        headers: {},
      })
      .then((response) => {
        this.setState({ tandctext: response.data.TandC });
        this.setState({ loadingTandC: false });
      })
      .catch((error) => {
        this.setState({ loadingTandC: false });
      });
  };
  toggleCheckboxValueTandC = () => {
    this.setState({ tandc: !this.state.tandc });
  };
  toggleTandC = () => {
    this.setState({
      modalTandC: !this.state.modalTandC,
    });
  };
  render() {
    return (
      <>
        <Suspense
          fallback={
            <div className="overlay" id="overlay" style={{ display: "block" }}>
              <div className="overlayMessage">
                <div className="spinner-border spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          }
        >
          <CreateAccount2Component
            toggleCheckboxValueTandC={this.toggleCheckboxValueTandC}
            toggleTandC={this.toggleTandC}
            tandc={this.state.tandc}
            tandctext={this.state.tandctext}
            modalTandC={this.state.modalTandC}
            loadingTandC={this.state.loadingTandC}
            logo={this.props.logoLogin}
            brand={this.props.brand}
          />
          <Footer />
        </Suspense>
      </>
    );
  }
}
export default CreateAccount1;
