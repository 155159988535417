import React, { Component } from "react";
import {
	MDBBtn,
	MDBModal,
	MDBModalBody,
	MDBModalHeader,
	MDBModalFooter,
	MDBRow,
	MDBCol,
	MDBInput,
	toast,
	ToastContainer
} from "mdbreact";
import axios from "../../../axios";
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import { withTranslation } from "react-i18next";
// import brandsData from "../../../brands/common/brands.json";
import SelectSearch, { fuzzySearch } from "react-select-search";
class MyAccount extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			loadingReset: false,
			realpageSyncDate: undefined,
			UpgradeServicePlanName: this.props.UpgradeServicePlanName,
			selectedUnit: 0
		};
	}
	componentDidMount() {
		this.listKeys();
		this.getPaymentMethod();
		this.setState({
			stripeClientSecretSubscriber: sessionStorage.getItem("stripeClientSecretSubscriber"),
			servicePlanPrice: sessionStorage.getItem("servicePlanPriceSubscriber")
		})
	}
	listKeys = () => {
		let firstKeyID;
		let firstPolicyID;
		let keys;
		let venueList;
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
		};
		axios
			.post("/subscriberconsole/listKeys", body, {
				headers: {},
			})
			.then((response) => {
				keys = response.data.KeysInKeyPools;
				venueList = Object.keys(response.data.KeysInKeyPools);
				this.setState({ venueList: venueList });
				this.setState({ keys: [keys] });
				this.setState({ venueName: this.state.venueList });
				if (this.state.venueName.length !== 0) {
					for (let i = 0; i < Object.keys(this.state.keys[0][this.state.venueName[0]]).length; i++) {
						if(Object.keys(this.state.keys[0][this.state.venueName[0]])[i] !== "DisplayOrder"){
							if(Object.keys(this.state.keys[0][this.state.venueName[0]])[i] !== "ssid"){
							this.setState({ selectedKeyID: Object.keys(this.state.keys[0][this.state.venueName[0]])[i] });
							break;
							}
						}
					}
				}
				if (this.state.venueName.length !== 0) {
					firstKeyID = this.state.selectedKeyID;
					firstPolicyID = Object.keys(this.state.keys[0][this.state.venueName[0]][firstKeyID]["VenueUnitPolicies"]);
					this.setState({
						email: response.data["EmailAddress"],
						firstName: response.data["GivenName"],
						lastName: response.data["FamilyName"],
						phoneNumber: response.data["PhoneNumber.E164"],
						language: response.data["locale"],
						confirmations: response.data["contactpref_email"],
						agree: response.data["contactpref_sms"],
						managedBySSO: response.data["SubscriberManagedviaSSO"],
						subscriberToken: response.data["SubscriberToken"]
					});
										let optionVenueUnitPolicy = [];
					Object.keys(
						response.data["KeysInKeyPools"][this.state.venueName[0]][firstKeyID]["VenueUnitPolicies"]
					).map((oneKey, i) => {
						optionVenueUnitPolicy.push({ value: i, name: response.data["KeysInKeyPools"][this.state.venueName[0]][firstKeyID]["VenueUnitPolicies"][firstPolicyID[i]]["UnitID"] + ", Building: " + response.data["KeysInKeyPools"][this.state.venueName[0]][firstKeyID]["VenueUnitPolicies"][firstPolicyID[i]]["UnitBuilding"] + ", Floor: " + response.data["KeysInKeyPools"][this.state.venueName[0]][firstKeyID]["VenueUnitPolicies"][firstPolicyID[i]]["UnitFloor"]});
						return true;
					});
					this.setState({
						optionVenueUnitPolicy: optionVenueUnitPolicy,
					});
					if (Object.keys(response.data["KeysInKeyPools"][this.state.venueName[0]][firstKeyID]["VenueUnitPolicies"]).length !== 0) {
						this.setState({
							address1:
								response.data["KeysInKeyPools"][this.state.venueName[0]][firstKeyID]["VenueUnitPolicies"][firstPolicyID[this.state.selectedUnit]]["AddressLine1"],
							address2:
								response.data["KeysInKeyPools"][this.state.venueName[0]][firstKeyID]["VenueUnitPolicies"][firstPolicyID[this.state.selectedUnit]]["AddressLine2"],
							city: response.data["KeysInKeyPools"][this.state.venueName[0]][firstKeyID]["VenueUnitPolicies"][firstPolicyID[this.state.selectedUnit]]["City"],
							state: response.data["KeysInKeyPools"][this.state.venueName[0]][firstKeyID]["VenueUnitPolicies"][firstPolicyID[this.state.selectedUnit]]["State"],
							postalCode:
								response.data["KeysInKeyPools"][this.state.venueName[0]][firstKeyID]["VenueUnitPolicies"][firstPolicyID[this.state.selectedUnit]]["PostalCode"],
							servicePlan:
								response.data["KeysInKeyPools"][this.state.venueName[0]][firstKeyID]["VenueUnitPolicies"][firstPolicyID[this.state.selectedUnit]]["ServicePlan"][
									"ServicePlanName"
								],
							serviceStart: response.data["KeysInKeyPools"][this.state.venueName[0]][firstKeyID]["VenueUnitPolicies"][firstPolicyID[this.state.selectedUnit]][
									"StartDateTimeUTC.ISO8601"
								],
							endDate:
								response.data["KeysInKeyPools"][this.state.venueName[0]][firstKeyID]["VenueUnitPolicies"][firstPolicyID[this.state.selectedUnit]][
									"EndDateTimeUTC.ISO8601"
								]!==""?response.data["KeysInKeyPools"][this.state.venueName[0]][firstKeyID]["VenueUnitPolicies"][firstPolicyID[this.state.selectedUnit]][
									"EndDateTimeUTC.ISO8601"
								]:undefined,
							realpageSyncDate: response.data["KeysInKeyPools"][this.state.venueName[0]][firstKeyID]["VenueUnitPolicies"][firstPolicyID[this.state.selectedUnit]][
									"RealPageSyncDateTimeUTC.ISO8601"
								],
							unitID_internal: response.data["KeysInKeyPools"][this.state.venueName[0]][firstKeyID]["VenueUnitPolicies"][firstPolicyID[this.state.selectedUnit]][
									"UnitID_internal"
								],
							stripeClientSecretSubscriber: response.data["KeysInKeyPools"][this.state.venueName[0]][firstKeyID]["VenueUnitPolicies"][firstPolicyID[this.state.selectedUnit]][
								"StripeClientSecret"
							]
						});
						sessionStorage.setItem("stripeClientSecretSubscriber", response.data["KeysInKeyPools"][this.state.venueName[0]][firstKeyID]["VenueUnitPolicies"][firstPolicyID[this.state.selectedUnit]][
							"StripeClientSecret"
						])
						sessionStorage.setItem("setupIntentID", response.data["KeysInKeyPools"][this.state.venueName[0]][firstKeyID]["VenueUnitPolicies"][firstPolicyID[this.state.selectedUnit]][
							"SetupIntentId"
						])
					}
				} else {
					this.setState({
						email: response.data["EmailAddress"],
						firstName: response.data["GivenName"],
						lastName: response.data["FamilyName"],
						phoneNumber: response.data["PhoneNumber.E164"],
						language: response.data["locale"],
						confirmations: response.data["contactpref_email"],
						agree: response.data["contactpref_sms"],
						managedBySSO: response.data["SubscriberManagedviaSSO"]
					});
				}
			})
			.catch((error) => {
				this.setState({ error: error });
			});
	};
	getPaymentMethod = () => {
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			StripeClientSecret: sessionStorage.getItem("stripeClientSecretSubscriber")
		};
		axios
			.post("/subscriberconsole/listPaymentMethod", body, {
				headers: {},
			})
			.then((response) => {
				this.setState({
					card: response.data.card,
					wallet: response.data.wallet
				})
			})
			.catch((error) => {
				this.setState({ error: error });
			});
	}
	submitHandler = () => {
		this.setState({ loading: true });
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			GivenName: this.state.firstName,
			FamilyName: this.state.lastName,
			"PhoneNumber.E164": this.state.phoneNumber,
			locale: this.state.language,
			contactpref_email: this.state.confirmations,
			contactpref_sms: this.state.agree,
			SubscriberToken: this.state.subscriberToken
		};
		axios
			.post("/subscriberconsole/updatePII", body, {
				headers: {},
			})
			.then((response) => {
				sessionStorage.setItem("firstName", this.state.firstName);
				sessionStorage.setItem("lastName", this.state.lastName);
				sessionStorage.setItem("phoneNumber", this.state.phoneNumber);
				toast.success("Account Updated!", {
					closeButton: false,
				});
				this.setState({ loading: false });
				this.props.toggleMyAccount();
			})
			.catch((error) => {
				toast.error(error.response.data.message, {
					closeButton: false,
				});
				this.props.toggleMyAccount();
				this.setState({ error: true });
				this.setState({ loading: false });
			});
	};
	changeHandler = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};
	changeHandlerPhone = (event) => {
		this.setState({ phoneNumber: event });
		var value = event ? (isPossiblePhoneNumber(event) ? undefined : "Please provide a valid phone number") : "Phone number required";
		this.setState({ invalidPhone: value });
	};
	toggleCheckboxValueConfirmations = () => {
		this.setState({ confirmations: !this.state.confirmations });
	};
	toggleCheckboxValueUpdates = () => {
		this.setState({ updates: !this.state.updates });
	};
	toggleCheckboxValueAgree = () => {
		this.setState({ agree: !this.state.agree });
	};
	toggleResetPassword = () => {
		this.setState({
			modalResetPassword: !this.state.modalResetPassword,
		});
	};
	resetPassword = () => {
		let data;
		this.setState({ loadingReset: true });
		data = {
			EmailAddress: this.state.email,
			UserTimeZone: new Date().getTimezoneOffset(),
		};
		axios
			.post("/subscriberconsole/initiateResetConsolePassword", data, {
				headers: {},
			})
			.then((response) => {
				toast.success("Password reset initiated, please check your email for instructions!", {
					closeButton: false,
				});
				this.setState({ loadingReset: false });
				this.props.toggleMyAccount();
			})
			.catch((error) => {
				toast.error("An error occurred", {
					closeButton: false,
				});
				this.setState({ loadingReset: true });
			});
	};
	showUpgradeModal = () => {
		this.setState({
			modalUpgrade: !this.state.modalUpgrade,
		});
	};
	handleSelectChangeUnit = (value) => {
		if (value !== null) {
			this.setState({ selectedUnit: value });
			this.listKeys();
		}
	};
	render() {
				const FORMAT = "MM/dd/yyyy";
		const { t } = this.props;
		// let showCancel;
		// let showUpgrade;
		let phoneNumber;
		let emailAddress;
		// let showUpdate;
		// brandsData.forEach(({ name, url, phone_number, flag_cancel, flag_upgrade, email_address, flag_update }) => {
  //     		if (url.includes(window.location.hostname)) {
  //       		// showCancel = flag_cancel;
  //       		// showUpgrade = flag_upgrade;
  //       		// phoneNumber = phone_number;
		// 		// emailAddress = email_address;
		// 		// showUpdate = flag_update;
  //     		}
  //   	});
		return (
			<>
				<ToastContainer hideProgressBar={true} newestOnTop={true} autoClose={10000} />
				<MDBModal isOpen={this.state.modalUpgrade} toggle={this.showUpgradeModal} centered>
						<MDBModalHeader toggle={this.showUpgradeModal} className="noBorder">
						</MDBModalHeader>
						<MDBModalBody className="updatePayment">
							<h2>Modify Service Plan Feature Coming Soon</h2>
							<p>In order to process your request please contact our Customer Support team at {sessionStorage.getItem("supportPhone")} or send us an email to <a href={"mailto:"+sessionStorage.getItem("emailAddress")}>{sessionStorage.getItem("emailAddress")}</a>, with your information and desired speed to complete the change for your account.</p>
						</MDBModalBody>
					</MDBModal>
				<MDBModal isOpen={this.state.modalResetPassword} toggle={this.toggleResetPassword} size="sm" className="smallModal" centered>
					<MDBModalHeader toggle={this.toggleResetPassword}>
						<h4>{t("myaccount.resetpassword")}</h4>
					</MDBModalHeader>
					<MDBModalBody>
						<div>
							<MDBBtn onClick={this.resetPassword} color="danger" block bsSize="large" type="submit">
								{t("myaccount.resetpassword")}
							</MDBBtn>
						</div>
					</MDBModalBody>
				</MDBModal>
				<MDBModalHeader toggle={this.props.toggleMyAccount}>
					<h4>{t("myaccount.title")}</h4>
				</MDBModalHeader>
				<MDBModalBody>
					<form className="needs-validation" noValidate>
						<MDBRow className="mb-3">
							<MDBCol col="6">
								<div>
									<label
										htmlFor="defaultFormLoginEmailEx"
										className="black-text"
									>
										Unit
									</label>
									<SelectSearch
										id="selectUnit"
										options={this.state.optionVenueUnitPolicy}
										search={true}
										filterOptions={fuzzySearch}
										onChange={this.handleSelectChangeUnit}
										isOptionSelected={true}
										inputId="buildingArray"
										classNamePrefix="building-select"
										placeholder="Select..."
										value={this.state.selectedUnit}
									/>
									<div className="invalid-feedback">
										Please provide a valid language.
									</div>
								</div>
							</MDBCol>
						</MDBRow>
						<MDBRow className="mb-2">
							<MDBCol col="6">
								<MDBRow>
									<MDBCol sm="6" lg="4">
										<p>{t("myaccount.email")}</p>
									</MDBCol>
									<MDBCol sm="6" lg="8">
										{this.state.email}
									</MDBCol>
								</MDBRow>
							</MDBCol>
							<MDBCol col="6"></MDBCol>
						</MDBRow>
						<MDBRow className="mb-2">
							<MDBCol sm="12" lg="6">
								<MDBRow className="mb-2">
									<MDBCol sm="3" lg="4" middle>
										<p>{t("myaccount.firstname")}</p>
									</MDBCol>
									<MDBCol sm="9" lg="8">
										<input
											disabled={
												this.state.managedBySSO===true||this.state.realpageSyncDate!==""?true:false
											}
											required
											id="profile"
											className="form-control"
											onChange={this.changeHandler}
											name="firstName"
											value={this.state.firstName}
										/>
										<div className="invalid-feedback">Please provide a valid profile name.</div>
									</MDBCol>
								</MDBRow>
							</MDBCol>
							<MDBCol sm="12" lg="6">
								<MDBRow>
									<MDBCol sm="3" lg="4" middle>
										<p>{t("myaccount.lastname")}</p>
									</MDBCol>
									<MDBCol sm="9" lg="8">
										<input
											disabled={this.state.managedBySSO===true||this.state.realpageSyncDate!==""?true:false}
											required
											id="profile"
											className="form-control"
											onChange={this.changeHandler}
											name="lastName"
											value={this.state.lastName}
										/>
										<div className="invalid-feedback">Please provide a valid profile name.</div>
									</MDBCol>
								</MDBRow>
							</MDBCol>
						</MDBRow>
						<MDBRow className="mb-2">
							<MDBCol sm="12" lg="6">
								<MDBRow>
									<MDBCol sm="3" lg="4" middle>
										<p>{t("myaccount.phone")}</p>
									</MDBCol>
									<MDBCol sm="9" lg="8">
										<PhoneInput
											disabled={this.state.managedBySSO===true||this.state.realpageSyncDate!==""?true:false}
											placeholder="Enter Phone Number"
											value={this.state.phoneNumber}
											format={FORMAT}
											onChange={this.changeHandlerPhone}
											name="phoneNumber"
											id="phoneNumber"
											className="form-control"
											required
											error={
												this.state.phoneNumber
													? isValidPhoneNumber(this.state.phoneNumber)
														? undefined
														: "Invalid phone number"
													: "Phone number required"
											}
										/>
										{this.state.invalidPhone !== null ? <div className="invalid">{this.state.invalidPhone}</div> : ""}
									</MDBCol>
								</MDBRow>
							</MDBCol>
							<MDBCol sm="12" lg="6">
								<MDBRow>
									<MDBCol sm="3" lg="4" middle>
										<p>{t("myaccount.language")}</p>
									</MDBCol>
									<MDBCol sm="9" lg="8">
									<div class="select_box">
										<select
											disabled={this.state.managedBySSO===true||this.state.realpageSyncDate!==""?true:false}
											className="browser-default custom-select"
											name="language"
											required
											onChange={this.changeHandler}
											value={this.state.language}
										>
											<option value="" disabled selected>
												Select
											</option>
											<option value="en-us" selected={this.state.language==="en-us"?true:false}>English</option>
											<option value="es-xl" selected={this.state.language==="es-xl"?true:false}>Spanish</option>
										</select>
									</div>
										<div className="invalid-feedback">Please provide a valid language.</div>
									</MDBCol>
								</MDBRow>
							</MDBCol>
						</MDBRow>
						<MDBRow className="mb-2">
							<MDBCol sm="12" lg="6">
								<MDBRow className="mb-2">
									<MDBCol sm="3" lg="4">
										<p>{t("myaccount.address1")}</p>
									</MDBCol>
									<MDBCol sm="9" lg="8">
										<p>{this.state.address1}</p>
									</MDBCol>
								</MDBRow>
							</MDBCol>
							<MDBCol sm="12" lg="6">
								<MDBRow className="mb-2">
									<MDBCol sm="3" lg="4">
										<p>{t("myaccount.address2")}</p>
									</MDBCol>
									<MDBCol col="8"><p>{this.state.address2}</p></MDBCol>
								</MDBRow>
							</MDBCol>
						</MDBRow>
						<MDBRow className="mb-2">
							<MDBCol sm="12" lg="6">
								<MDBRow className="mb-2">
									<MDBCol sm="3" lg="4">
										<p>{t("myaccount.city")}</p>
									</MDBCol>
									<MDBCol sm="9" lg="8">
										<p>{this.state.city}</p>
									</MDBCol>
								</MDBRow>
							</MDBCol>
							<MDBCol sm="12" lg="6">
								<MDBRow className="mb-2">
									<MDBCol sm="3" lg="4">
										<p>{t("myaccount.state")}</p>
									</MDBCol>
									<MDBCol sm="9" lg="8">
										<p>{this.state.state}</p>
									</MDBCol>
								</MDBRow>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol sm="12" lg="6">
								<MDBRow className="mb-2">
									<MDBCol sm="3" lg="4">
										<p>{t("myaccount.postal")}</p>
									</MDBCol>
									<MDBCol sm="9" lg="8">
										<p>{this.state.postalCode}</p>
									</MDBCol>
								</MDBRow>
							</MDBCol>
						</MDBRow>
						<MDBRow className="mb-2">
							<MDBCol sm="12" lg="12">
									{sessionStorage.getItem("showUpdate") && this.state.stripeClientSecretSubscriber && this.state.servicePlanPrice !== "0" && ( this.state.wallet || this.state.card) ?
									<>
									<hr/>
									{ this.state.card ? 
									<div className="ccInfo">
										Credit Card
										<MDBRow className="mb-2">
										<MDBCol sm="12" lg="2">
										<p>
											<img className="creditCardIcon" src={"/creditcardicons/"+this.state.card.brand+".png"} alt={this.state.card.brand}/>
										</p>
										</MDBCol>
										<MDBCol sm="12" lg="10">
										<p className="ccNumber">
											**** **** **** {this.state.card.last4}
										</p>
										<p>
											Expiration Date: {this.state.card.exp_month}/{this.state.card.exp_year}
										</p>
										</MDBCol>
										</MDBRow>
										<a
											className="btn Ripple-parent btn-default centered"								
											href="/update/checkout?ui=myaccount"
										>
											Modify Billing CC
										</a> 
										</div>
									:
									<div className="ccInfo">
										Wallet
										<MDBRow className="mb-2">
										<MDBCol sm="12" lg="4">
										<p>
											<img className="creditCardIcon" src={"/creditcardicons/"+this.state.wallet.type+".png"} alt={this.state.wallet.type}/>
										</p>
										</MDBCol>
										<MDBCol sm="12" lg="8">
										<p className="ccNumber">
											**** **** **** {this.state.wallet.dynamic_last4}
										</p>
										</MDBCol>
										</MDBRow>
										<a
											className="btn Ripple-parent btn-default centered"								
											href="/update/checkout?ui=myaccount"
										>
											Modify Billing CC
										</a> 
										</div>
									}
									</>: ""}
							</MDBCol>
						</MDBRow>
						<hr/>
						<MDBRow className="mb-2">
							<MDBCol sm="12" lg="6">
								<MDBRow className="mb-2">
									<MDBCol sm="3" lg="4">
										<p>{t("myaccount.servicetier")}</p>
									</MDBCol>
									<MDBCol sm="9" lg="8">
										<p>{this.state.servicePlan}</p>
									</MDBCol>
									{(sessionStorage.getItem("showUpgrade") && (this.state.stripeClientSecretSubscriber || sessionStorage.getItem("setupIntentID"))) ? 
									<a
										className="btn Ripple-parent btn-default centered upgradePlan"	
										href="/myaccount/upgrade"
									>
										Modify Service Plan
									</a> : ""}
								</MDBRow>
							</MDBCol>
						</MDBRow>
						<MDBRow className="mb-2">
							<MDBCol sm="12" lg="6">
								<MDBRow className="mb-2">
									<MDBCol sm="3" lg="4">
										<p>{t("myaccount.servicestart")}</p>
									</MDBCol>
									<MDBCol sm="9" lg="8">
										<p>{this.state.serviceStart !== undefined
											? new Intl.DateTimeFormat("en-US", {
													year: "numeric",
													month: "numeric",
													day: "numeric",
											  }).format(new Date(this.state.serviceStart))
											: ""}</p>
									</MDBCol>
								</MDBRow>
							</MDBCol>
							<MDBCol sm="12" lg="6">
								<MDBRow className="mb-2">
									<MDBCol sm="3" lg="4">
										<p>{t("myaccount.serviceend")}</p>
									{((this.props.showCancel === true && sessionStorage.getItem("stripeClientSecretSubscriber")) || this.state.realpageSyncDate==="") ?
										<a href="/myaccount/cancel" className="cancelService">Cancel Service</a> : "" }
									</MDBCol>
									<MDBCol sm="9" lg="8">
										<p>{this.state.endDate !== undefined
											? new Intl.DateTimeFormat("en-US", {
													year: "numeric",
													month: "numeric",
													day: "numeric",
											  }).format(new Date(this.state.endDate))
											: ""}</p>
									</MDBCol>
								</MDBRow>
							</MDBCol>
						</MDBRow>
						{/* <MDBRow className="mb-2">
							<MDBCol sm="12" lg="6">
								<p className="mb-0">{t("myaccount.emailnotification")}</p>
								<MDBInput
									disabled={this.state.managedBySSO===true||this.state.realpageSyncDate!==""?true:false}
									label={t("myaccount.emailnotificationlabel")}
									type="checkbox"
									id="confirmations"
									name="confirmations"
									onChange={this.toggleCheckboxValueConfirmations}
									checked={this.state.confirmations}
								/>
							</MDBCol>
							<MDBCol col="6"></MDBCol>
						</MDBRow> */}
						<MDBRow className="mb-2">
							<MDBCol sm="12" lg="6">
								<p className="mb-0 mt-2">{t("myaccount.textnotification")}</p>
								<MDBInput
									disabled={this.state.managedBySSO===true||this.state.realpageSyncDate!==""?true:false}
									label="Allow account notifications via SMS"
									type="checkbox"
									id="agree"
									name="agree"
									onChange={this.toggleCheckboxValueAgree}
									checked={this.state.agree}
								/>
							</MDBCol>
							<MDBCol sm="12" lg="6"></MDBCol>
						</MDBRow>
					</form>
				</MDBModalBody>
				<MDBModalFooter>
					<MDBBtn color="black" onClick={this.resetPassword}>
						{this.state.loadingReset === true ? (
							<div className="spinner-border spinner-border-sm" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						) : (
							t("myaccount.resetpassword")
						)}
					</MDBBtn>
					<MDBBtn color="danger" onClick={this.submitHandler} type="submit">
						{this.state.loading === true ? (
							<div className="spinner-border spinner-border-sm" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						) : (
							"OK"
						)}
					</MDBBtn>
				</MDBModalFooter>
			</>
		);
	}
}
export default withTranslation()(MyAccount);