import React from 'react';

import { MDBDataTableV5 } from 'mdbreact';

import { useTranslation } from 'react-i18next';

export default function Basic(props) {
  const { t } = useTranslation();
  let userAttributes;
  let i = 0;

   let [datatable] = React.useState([]);
    if(props.keyNameListArrayAll!==undefined){
    userAttributes = [];
    for (const key of Object.keys(props.keyNameListArrayAll)) {
      let keyid = props.keyNameListArrayAll[key][0];
      let keytag = props.keyNameListArrayAll[key][1]['KeyTag']
      let keypsk = props.keyNameListArrayAll[key][1]['psk']
      let keynetwork = props.keyNameListArrayAll[key][1]['network']
      let keyselectednetworkindex = props.keyNameListArrayAll[key][1]['selectedNetworkIndex']
      let iswpa2 = props.keyNameListArrayAll[key][1]['wpa2enterprise_auth']
      let devices = props.keyNameListArrayAll[key][1]['Devices']
      userAttributes.push({
           status: props.keyNameListArrayAll[key][1]['currentState']==="Active"?<div className="active-circle"></div>:<div className="inactive-circle"></div>,
           profile: keytag,
           network: props.keyNameListArrayAll[key][1]['network'],
           activated: props.keyNameListArrayAll[key][1]['StartDateTimeUTC.ISO8601']!==undefined?Intl.DateTimeFormat('en-US',{
                                                  month: 'numeric',
                                                  day: 'numeric',
                                                  year: 'numeric',
                                                }).format(new Date(props.keyNameListArrayAll[key][1]['StartDateTimeUTC.ISO8601'][0])):"",
            expiring: "",
           clickEvent: ()=>props.toggleManageKey(true, keyid, keytag, keypsk, keynetwork, keyselectednetworkindex, iswpa2, devices)

      })
      
      i = i + 1;
    }
  }
    
    datatable = ({
    columns: [
      
      {
        label: t('manageprofilestable.status'),
        field: 'status',
      },
      {
        label: t('manageprofilestable.profile'),
        field: 'profile',
      },
      {
        label: t('manageprofilestable.network'),
        field: 'network',
      },
      {
        label: t('recentservice.activated'),
        field: 'activated',
      },
      {
        label: t('recentservice.expiring'),
        field: 'expiring',
      }
    ],
    rows: userAttributes
  });
  return <MDBDataTableV5 
            hover 
            data={datatable} 
            searchBottom={false} 
            searchTop={true}
            paging={false}
            className="profilesData"
            info={false}
            noRecordsFoundLabel={t('recentservice.nomatches')}
            responsive
            />;
}